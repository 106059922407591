import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles from '@material-ui/styles/withStyles';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AutocompleteItem from './autocompleteItem';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      isVisible: false,
      selected: -1,
    };
    this.inputRef = React.createRef();
  }

  onBlurHandler = () => {
    this.setState({
      isVisible: false,
      focused: false,
    });
  };

  onFocusHandler = () => {
    const { onChange } = this.props;
    this.setState({
      isVisible: true,
      focused: true,
    });
    if (onChange) {
      onChange('');
    }
    if (this.inputRef.current) {
      this.inputRef.current.children[0].focus();
    }
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.target.value);
    }
  };

  selectionHandler = (item, index) => {
    const { onSelected } = this.props;
    this.setState({
      isVisible: false,
      focused: false,
    });
    onSelected(item, index);
  };

  optionsKeyboardHandler = (e) => {
    const { data } = this.props;
    const { selected } = this.state;
    let newSelected = selected;
    if (e.key === 'ArrowDown') {
      if (selected + 1 < data.length) {
        newSelected++;
      } else {
        newSelected = 0;
      }
    } else if (e.key === 'ArrowUp') {
      if (selected - 1 > 0) {
        newSelected--;
      } else {
        newSelected = data.length - 1;
      }
    } else if (e.key === 'Enter') {
      this.selectionHandler(data[selected], selected);
      return;
    }
    this.setState({ selected: newSelected });
  };

  render() {
    const {
      data = [],
      label = '',
      required,
      value,
      error,
      placeholder,
      classes,
      extraClass = '',
      inputClass = '',
      accessor = '',
      popupClass = '',
      labelClass = '',
      bodyClass = '',
      variant = '',
      customRender,
      disabled = false,
      id = '',
      secondaryAccessor,
      renderType,
    } = this.props;
    const { isVisible, focused, selected } = this.state;
    const rootClass = label ? 'labelRoot' : 'root';
    const Icon = focused ? KeyboardArrowUp : KeyboardArrowDown;
    const idField = id || accessor;
    return (
      <div className={clsx(classes.container, extraClass)}>
        {label ? (
          <Typography
            className={clsx(
              classes.label,
              labelClass,
              variant !== 'no-outline' && classes.labelMargin,
              focused && classes.focusedLabel
            )}
          >
            {label}
            {required && <sup className={classes.required}>*</sup>}
          </Typography>
        ) : null}
        <ClickAwayListener onClickAway={this.onBlurHandler}>
          <div
            className={clsx(
              classes[rootClass],
              bodyClass,
              variant !== 'no-outline' && classes.outline,
              focused && variant !== 'no-outline' && classes.focusedRoot,
              (error && variant !== 'no-outline') && classes.error
            )}
          >
            <InputBase
              ref={this.inputRef}
              disabled={disabled}
              className={clsx(classes.textField, inputClass)}
              autoComplete="off"
              placeholder={placeholder}
              value={value}
              classes={{
                input: classes.input,
                disabled: classes.disabledInput,
              }}
              onChange={(e) => this.handleChange(e)}
              onFocus={this.onFocusHandler}
              onKeyDown={this.optionsKeyboardHandler}
              endAdornment={(
                <IconButton
                  className={classes.iconBtn}
                  onClick={this.onFocusHandler}
                  disabled={disabled}
                >
                  <Icon />
                </IconButton>
              )}
            />
            {isVisible && data.length ? (
              <div className={clsx(classes.paper, popupClass)}>
                {data.map((item, index) => {
                  const isSelected = selected === index;
                  return (
                    <div
                      key={item[idField]}
                      role="button"
                      tabIndex="0"
                      className={clsx(classes.item, isSelected && classes.selected)}
                      onClick={() => this.selectionHandler(item, index)}
                    >
                      <AutocompleteItem
                        item={item}
                        index={index}
                        accessor={accessor}
                        secondaryAccessor={secondaryAccessor}
                        customRender={customRender}
                        renderType={renderType}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  outline: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
  },
  required: {
    verticalAlign: 'top',
    marginLeft: '6px',
    fontWeight: 'bold',
  },
  labelMargin: {
    marginBottom: 10,
  },
  focusedRoot: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  focusedLabel: {
    color: theme.colors.primary,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  labelRoot: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
    zIndex: 1301,
    width: '100%',
    borderRadius: 4,
    maxHeight: 200,
    top: 39,
    overflowY: 'auto',
  },
  textField: {
    height: '100%',
    cursor: 'pointer',
  },
  item: {
    fontSize: 16,
    fontFamily: 'Lato',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 15px',
    color: theme.colors.black,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  primaryText: {
    margin: 0,
    fontSize: 16,
    color: theme.colors.black,
  },
  secondaryText: {
    margin: '6px 0 0 0',
    fontSize: 14,
    color: theme.colors.textLight,
  },
  selected: {
    backgroundColor: theme.colors.primaryLight,
  },
  error: {
    border: `1px solid ${theme.colors.red}`,
  },
  input: {
    padding: 0,
    height: '100%',
    cursor: 'pointer',
    fontSize: 16,
  },
  disabledInput: {
    cursor: 'not-allowed',
  },
  iconBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

Autocomplete.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  extraClass: PropTypes.string,
  inputClass: PropTypes.string,
  accessor: PropTypes.string.isRequired,
  popupClass: PropTypes.string,
  labelClass: PropTypes.string,
  bodyClass: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  customRender: PropTypes.func,
  id: PropTypes.string,
  secondaryAccessor: PropTypes.string,
  renderType: PropTypes.string,
};

export default withStyles(styles)(Autocomplete);
