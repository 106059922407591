import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TemplatesIndex from './templatesIndex';
import { saveItineraryDraft } from '../itineraryMaker/itineraryMakerActions';

const mapStateToProps = (state) => ({
  meta: state.common.paramsMeta,
  saveItineraryDraftResp: state.itineraryMaker.saveItineraryDraftResp,
});

const mapDispatchToProps = (dispatch) => ({
  saveItineraryDraft: (params, headers) => dispatch(saveItineraryDraft(params, headers)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TemplatesIndex)
);
