import moment from 'moment';
import axios from 'axios';
import {
  MONTH_MAP, ITINERARY_READ_MODES, CHANGE_TYPES, ROLES,
} from './consts';

export const headerGenerator = (token = '') => {
  if (token) {
    axios.defaults.headers.common['x-access-token'] = token;
  }

  return {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
};

export const getEncodedParams = (params) => {
  const searchParams = [];
  Object.keys(params)
    .forEach((key) => {
      const encodedKey = encodeURIComponent(key);
      if (params[key] && params[key].constructor.name === 'Array') {
        const encodedValue = encodeURIComponent(JSON.stringify(params[key]));
        searchParams.push(`${encodedKey}=${encodedValue}`);
      } else {
        const encodedValue = encodeURIComponent(params[key]);
        searchParams.push(`${encodedKey}=${encodedValue}`);
      }
    });
  return searchParams.join('&');
};

export const setCookie = (saveObj) => {
  const keys = Object.keys(saveObj);
  const expiry = moment().endOf('day').toDate().toUTCString();
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    let value = '';
    if (typeof saveObj[key] === 'object') {
      value = JSON.stringify(saveObj[key]);
    } else {
      value = saveObj[key];
    }
    document.cookie = `${key}=${value};expires=${expiry};path=/;`;
  }
};

export const deleteCookies = (cookieArr) => {
  cookieArr.map((item) => {
    document.cookie = `${item}=;Max-Age=-1;`;
    return null;
  });
};

export const getCookies = () => {
  let cookies = decodeURIComponent(document.cookie);
  cookies = cookies.split(';');
  const cookieObj = {};
  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].split('=');
    if (key && value) {
      if (value[0] === '{') {
        cookieObj[key.trim()] = JSON.parse(value.trim());
      } else {
        cookieObj[key.trim()] = value.trim();
      }
    }
  }
  return cookieObj;
};

export const parseQueries = (path) => {
  const splits = path.split('?');
  const queries = {};
  if (splits.length > 1) {
    const givenQueries = splits[1].split('&');
    for (let i = 0; i < givenQueries.length; i++) {
      const [key, value] = givenQueries[i].split('=');
      queries[key] = value;
    }
  }
  return queries;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email)
    .toLowerCase());
};

export const validateURL = (link) => {
  // eslint-disable-next-line
  const regx = /^(?:https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
  return regx.test(link);
};

export const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const omit = (omitObject, omits) => {
  const keys = Object.keys(omitObject);
  const newObj = {};
  const omitObj = {};
  for (let i = 0; i < omits.length; i++) {
    omitObj[omits[i]] = 1;
  }
  for (let i = 0; i < keys.length; i++) {
    if (!omitObj[keys[i]]) {
      newObj[keys[i]] = omitObject[keys[i]];
    }
  }
  return newObj;
};

export const isEqual = (first, second) => {
  if (first === second) {
    return true;
  }
  if ((first === undefined || second === undefined || first === null || second === null)
    && (first || second)) {
    return false;
  }
  const firstType = first.constructor.name;
  const secondType = second.constructor.name;
  if (firstType !== secondType) {
    return false;
  }
  if (firstType === 'Array') {
    if (first.length !== second.length) {
      return false;
    }
    let equal = true;
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  if (firstType === 'Object') {
    let equal = true;
    const fKeys = Object.keys(first);
    const sKeys = Object.keys(second);
    if (fKeys.length !== sKeys.length) {
      return false;
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (first[fKeys[i]] && second[fKeys[i]]) {
        if (first[fKeys[i]] === second[fKeys[i]]) {
          continue; // eslint-disable-line
        }
        if (first[fKeys[i]] && (first[fKeys[i]].constructor.name === 'Array'
          || first[fKeys[i]].constructor.name === 'Object')) {
          equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
          if (!equal) {
            break;
          }
        } else if (first[fKeys[i]] !== second[fKeys[i]]) {
          equal = false;
          break;
        }
      } else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  return first === second;
};

export const deepClone = (item) => {
  let clonedItem = item;
  if (item === null || item === undefined || item.constructor.name === 'Number'
    || item.constructor.name === 'String') {
    return clonedItem;
  }
  if (item.constructor.name === 'Object') {
    const keys = Object.keys(item);
    clonedItem = {};
    for (let i = 0; i < keys.length; i++) {
      clonedItem[keys[i]] = deepClone(item[keys[i]]);
    }
  }
  if (item.constructor.name === 'Array') {
    clonedItem = new Array(item.length);
    for (let i = 0; i < item.length; i++) {
      clonedItem[i] = deepClone(item[i]);
    }
  }
  return clonedItem;
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function () {
    // eslint-disable-next-line prefer-rest-params
    const next = () => func.apply(this, arguments);
    if (immediate && !timeout) {
      next();
    }
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

export const filterList = (value, data = [], accessors = []) => {
  if (value.length) {
    const regx = new RegExp(value.replace(/\\/g, '&#92;'), 'i');
    return data.filter((d) => accessors.some((a) => regx.test(d[a])));
  }
  return data;
};

const validateLength = (validation, val) => {
  return (val && val.length >= validation.args[0] && val.length <= validation.args[1]);
};

const validateNum = (validation, val) => {
  return (val && +val >= validation.args[0] && +val <= validation.args[1]);
};

const validateObj = (validation, val) => {
  return val && Object.keys(val).length >= 1;
};

export const validateKey = (validations, val) => {
  let isValid = true;
  validations.forEach((validation) => {
    switch (validation.type) {
      case 'length':
        isValid = validateLength(validation, val);
        break;
      case 'email':
        isValid = validateEmail(val);
        break;
      case 'number':
        isValid = validateNum(validation, val);
        break;
      case 'object':
        isValid = validateObj(validation, val);
        break;
      default:
        isValid = true;
    }
  });
  return isValid;
};

export const getTravellingDates = (routes) => {
  const travellingDays = [];
  for (let j = 0; j < routes.length; j++) {
    const { from, to } = routes[j].dates;
    const days = (moment(to).unix() - moment(from).unix()) / 86400;
    let initDay = moment(from).unix() * 1000;
    travellingDays.push(moment(from).format('DD MMM YY'));
    for (let i = 1; i <= days; i++) {
      initDay += 86400000;
      const newDay = initDay;
      const day = moment(newDay).format('DD MMM YY');
      travellingDays.push(day);
    }
  }
  return travellingDays;
};

export const spacedToCamelStr = (str = '') => {
  const splits = str.split(' ');
  const strs = [];
  strs.push(splits[0].toLowerCase());
  for (let i = 1; i < splits.length; i++) {
    const patStr = splits[i][0].toUpperCase() + splits[i].slice(1)
      .toLowerCase();
    strs.push(patStr);
  }
  return strs.join('');
};

const camelToSpacedStr = (str = '') => {
  const splits = str.split('');
  const strs = [];
  let prevIndex = 0;
  for (let i = 0; i < splits.length; i++) {
    const code = splits[i].charCodeAt(0);
    if (code >= 65 && code <= 90) {
      strs.push(str.slice(prevIndex, i)
        .toLowerCase());
      prevIndex = i;
    }
  }
  strs.push(str.slice(prevIndex)
    .toLowerCase());
  strs[0] = strs[0].slice(0, 1)
    .toUpperCase() + strs[0].slice(1);
  return strs.join(' ');
};

export const validator = (obj) => {
  const keys = Object.keys(obj);
  let valid = true;
  let errorMsg = '';
  for (let i = 0; i < keys.length; i++) {
    const item = obj[keys[i]];
    if (item.constructor.name === 'object' && (!item.valid || !item.value.length)) {
      valid = false;
      errorMsg = `${camelToSpacedStr(keys[i])} is invalid`;
      break;
    } else if (item.constructor.name === 'String' && item.trim().length === 0) {
      valid = false;
      errorMsg = `${camelToSpacedStr(keys[i])} cannot be empty`;
      break;
    }
  }
  return {
    valid,
    errorMsg,
  };
};

export const getValidDates = (startDate, endDate) => {
  const sDate = moment(startDate).format('YYYY-MM-DD');
  const eDate = moment(endDate).format('YYYY-MM-DD');
  return (date) => {
    const currDate = moment(date).format('YYYY-MM-DD');
    return !(currDate >= sDate && currDate <= eDate);
  };
};

export const getFileExtension = (fileName) => {
  const extension = fileName.split('.').pop();
  const resp = {
    extension: '',
    contentType: '',
  };
  const lowerExtension = extension.toLowerCase();
  if (lowerExtension === 'pdf') {
    resp.extension = extension;
    resp.contentType = 'application/pdf';
  } else if (lowerExtension === 'jpg') {
    resp.extension = extension;
    resp.contentType = 'image/jpg';
  } else if (lowerExtension === 'jpeg') {
    resp.extension = extension;
    resp.contentType = 'image/jpeg';
  } else if (lowerExtension === 'png') {
    resp.extension = extension;
    resp.contentType = 'image/png';
  }
  return resp;
};

export const addTravellerFiles = (travellerDetails, uploadArr, uploadMap) => {
  const modArr = [...uploadArr];
  const modMap = { ...uploadMap };
  const uploadTemplate = {
    fileName: '',
    fileCategory: 'ITINERARY_PART',
    ttlMinutes: 2,
    extension: 'jpeg',
    ContentType: 'image/jpeg',
  };
  const keys = Object.keys(travellerDetails);
  for (let i = 0; i < keys.length; i++) {
    for (let j = 0; j < travellerDetails[keys[i]].length; j++) {
      const traveller = travellerDetails[keys[i]][j];
      for (let k = 0; k < traveller.documents.length; k++) {
        const travellerDocument = traveller.documents[k];
        if (travellerDocument.document.name) {
          const { contentType, extension } = getFileExtension(travellerDocument.document.name);
          modArr.push({
            ...uploadTemplate,
            fileName: travellerDocument.document.name,
            extension,
            ContentType: contentType,
          });
          modMap[travellerDocument.document.name] = {
            file: travellerDocument.document,
            fileName: travellerDocument.document.name,
          };
        }
      }
    }
  }
  return {
    uploadArr: modArr,
    uploadMap: modMap,
  };
};

export const mapTravellerFiles = (travellerDetails, uploadMap) => {
  const modDetails = { ...travellerDetails };
  const keys = Object.keys(travellerDetails);
  for (let i = 0; i < keys.length; i++) {
    for (let j = 0; j < travellerDetails[keys[i]].length; j++) {
      const traveller = modDetails[keys[i]][j];
      for (let k = 0; k < traveller.documents.length; k++) {
        const travellerDocument = traveller.documents[k];
        if (travellerDocument.document?.name) {
          travellerDocument.document = uploadMap[travellerDocument.document.name].document;
        } else if (!travellerDocument.document?.fileName) {
          delete travellerDocument.document;
        }
      }
    }
  }
  return modDetails;
};

export const addFilesToUpload = (index, docs = [], uploadArr, uploadMap) => {
  const modArr = [...uploadArr];
  const modMap = { ...uploadMap };
  const uploadTemplate = {
    fileName: '',
    fileCategory: 'ITINERARY_PART',
    ttlMinutes: 2,
    extension: 'jpeg',
    ContentType: 'image/jpeg',
  };
  for (let i = 0; i < docs.length; i++) {
    const doc = docs[i];
    if (doc.constructor.name === 'File') {
      const { extension, contentType } = getFileExtension(doc.name);
      modMap[doc.name] = {
        partIndex: index,
        file: doc,
        fileName: doc.name,
      };
      modArr.push({
        ...uploadTemplate,
        fileName: doc.name,
        extension,
        ContentType: contentType,
      });
    } else {
      modMap[doc.fileName] = {
        partIndex: index,
        fileName: doc.fileName,
        link: doc.link,
        _id: doc._id,
      };
    }
  }
  return {
    uploadArr: modArr,
    uploadMap: modMap,
  };
};

export const mapImageToFile = (documents = [], uploadMap) => {
  const docs = [];
  for (let i = 0; i < documents.length; i++) {
    const doc = documents[i];
    const { document, _id } = uploadMap[doc.name || doc.fileName];
    if (_id) {
      docs.push(_id);
    } else {
      docs.push(document);
    }
  }
  return docs;
};

export const convertRequirement = (requirement) => {
  const convertedRequirement = { ...requirement };
  const modDestinations = [...convertedRequirement.places.items];
  convertedRequirement.places = modDestinations.map((d) => d._id);
  convertedRequirement.origin = convertedRequirement.origin.items.map((o) => o._id);
  const tripItems = [...convertedRequirement.tripTypes.items];
  convertedRequirement.tripTypes = tripItems.map((t) => t.title);
  convertedRequirement.departureDate = moment(convertedRequirement.tripDates.from)
    .toISOString();
  convertedRequirement.arrivalDate = moment(convertedRequirement.tripDates.to)
    .toISOString();
  delete convertedRequirement.tripDate;
  delete convertedRequirement.tripDates;
  return convertedRequirement;
};

export const processRequirement = (requirement, tripMeta = []) => {
  const {
    arrivalDate, departureDate, duration, flightsBooked = false,
    travellers = {
      adults: 0,
      children: 0,
      infants: 0,
    },
    origin = [], places = [], flexible = false, tripTypes = [],
  } = requirement;
  const tripMap = {};
  for (let i = 0; i < tripMeta.length; i++) {
    tripMap[tripMeta[i].code] = tripMeta[i];
  }
  let finalTripTypes;
  if (tripTypes.length && tripTypes[0] === tripTypes[0].toUpperCase()) {
    finalTripTypes = tripTypes.map((t) => (tripMap[t]));
  } else {
    finalTripTypes = tripTypes.map((t) => ({ title: t, code: t.toUpperCase().replace(' ', '_') }));
  }
  return {
    tripDates: {
      from: moment(departureDate)
        .toDate(),
      to: moment(arrivalDate)
        .toDate(),
    },
    tripDate: getDateStr(departureDate, arrivalDate),
    duration,
    flightsBooked: Boolean(flightsBooked),
    isFlexible: Boolean(flexible),
    travellers,
    tripTypes: {
      value: '',
      valid: true,
      items: finalTripTypes,
    },
    origin: {
      value: '',
      valid: Boolean(origin[0]?.name),
      items: origin,
    },
    places: {
      value: '',
      valid: true,
      items: places,
    },
  };
};

export const getLastInstallmentCollectionDate = (tripDates) => {
  const today = moment().startOf('day').unix();
  const tripStartDate = moment(tripDates.from).unix();
  const daysLeft = (tripStartDate - today) / 86400;
  let lastCollectionDate;
  if (daysLeft >= 45) {
    lastCollectionDate = moment((tripStartDate - (45 * 86400)) * 1000);
  } else { // within 45 days
    lastCollectionDate = moment((today + (3 * 86400)) * 1000);
    if (lastCollectionDate >= moment(tripStartDate * 1000)) {
      lastCollectionDate = moment(tripStartDate * 1000).subtract(1, 'day');
    }
  }
  return lastCollectionDate.endOf('day');
};

export const addCollectionCondition = (collections, date, amount, errorMsg) => {
  const modCollections = { ...collections };
  if (!modCollections[date]) {
    modCollections[date] = {
      till: moment(date, 'DD MMM YY').endOf('day').unix() * 1000,
      amount: Number(amount),
      errorMsgs: [errorMsg],
    };
  } else {
    modCollections[date].amount += Number(amount);
    modCollections[date].errorMsgs.push(errorMsg);
  }
  return modCollections;
};

export const getItineraryConditions = (
  collectionConditions,
  itinerary,
  lastCollectionDate,
  token = 5000
) => {
  const { itineraryParts, pricing, landPackageBookingDate } = itinerary;
  let flightPrice = 0;
  let servicePrice = 0;
  let landPrice = 0;
  let modCollections = { ...collectionConditions };
  let includesLandPackage = false;
  for (let i = 0; i < itineraryParts.length; i++) {
    const sellingPrice = +(itineraryParts[i].sellPrice) / 100;
    if (itineraryParts[i].type === 'FLIGHT') {
      // if (itineraryParts[i].refundable) {
      //   const cautionAmount = itineraryParts[i].cancellationAmount + 2000;
      //   const flexAmount = sellingPrice - cautionAmount;
      //   const flexDate = moment(itineraryParts[i].bookingDate)
      //     .add(10, 'days')
      //     .format('DD MMM YY');
      //   modCollections = addCollectionCondition(modCollections,
      // bookingDate, cautionAmount, 'Flight caution amount');
      //   modCollections = addCollectionCondition(modCollections, flexDate, flexAmount,
      //  'Rest flight amount');
      // } else {
      //   modCollections = addCollectionCondition(modCollections, bookingDate
      // , sellingPrice, 'Flight amount');
      // }
      const tomorrowDate = moment(itineraryParts[i].bookingDate).add(1, 'day').format('DD MMM YY');
      modCollections = addCollectionCondition(modCollections, tomorrowDate, sellingPrice, 'Flight amount');
      flightPrice += +sellingPrice;
    } else if (itineraryParts[i].type === 'SERVICE') {
      servicePrice += +sellingPrice;
      const bookingDate = moment(itineraryParts[i].bookingDate || itineraryParts[i].created_at)
        .format('DD MMM YY');
      modCollections = addCollectionCondition(modCollections, bookingDate, sellingPrice, 'Service amount');
    } else {
      includesLandPackage = true;
    }
  }
  const lastCollectionMoment = moment(lastCollectionDate);
  if (includesLandPackage) {
    const sellingPrice = Number(pricing.landPackage.sellPrice);
    landPrice = Number(sellingPrice) - Number(pricing.discount);
    landPrice /= 100;
    const bookingDate = moment(landPackageBookingDate).format('DD MMM YY');
    let finalCollectionDate = lastCollectionMoment.format('DD MMM YY');
    // means not within 45 day range
    if ((lastCollectionMoment.unix() - moment(landPackageBookingDate).unix()) / 86400 < 4) {
      modCollections = addCollectionCondition(modCollections, bookingDate, token, 'Token amount');
      finalCollectionDate = moment(bookingDate).add(1, 'day').format('DD MMM YY');
      modCollections = addCollectionCondition(modCollections,
        finalCollectionDate, (landPrice - token), 'Rest of land package amount');
    } else {
      modCollections = addCollectionCondition(modCollections, bookingDate, token, 'Token amount');
      let next6Day = moment(landPackageBookingDate).add(6, 'days').format('DD MMM YY');
      if (moment(next6Day, 'DD MMM YY').unix() >= lastCollectionMoment.unix()) {
        next6Day = lastCollectionMoment.subtract(1, 'day').format('DD MMM YY');
      }
      const rest10Pct = (landPrice * 0.1) - token;
      if (rest10Pct < 0) {
        modCollections = addCollectionCondition(modCollections,
          finalCollectionDate, (landPrice * 0.9) + rest10Pct, 'Rest of land package amount');
      } else {
        modCollections = addCollectionCondition(modCollections,
          next6Day, (landPrice * 0.1) - token, '10% of package amount');
        modCollections = addCollectionCondition(modCollections,
          finalCollectionDate, (landPrice * 0.9), 'Rest of land package amount');
      }
    }
  }
  return {
    conditions: modCollections,
    flightPrice,
    landPrice,
    servicePrice,
  };
};

export const validateInstallmentConditions = (installments, cnds) => {
  const modInstallments = installments.map((installment) => ({
    amount: installment.amount,
    till: moment(installment.dueDate).unix() * 1000,
  }))
    .sort((a, b) => (a.till - b.till));
  let valid = true;
  let errorMsg = '';
  const conditions = Object.keys(cnds)
    .sort((a, b) => (cnds[a].till - cnds[b].till));
  const condsMap = {};
  for (let i = 0; i < conditions.length; i++) {
    condsMap[conditions[i]] = cnds[conditions[i]];
  }
  for (let k = 0; k < conditions.length; k++) {
    const { till, amount, errorMsgs } = condsMap[conditions[k]];
    let amountSoFar = 0;
    let installmentIndex = -1;
    for (let i = 0; i < modInstallments.length; i++) {
      if (modInstallments[i].till > till) {
        installmentIndex = i;
        break;
      } else {
        amountSoFar += modInstallments[i].amount;
      }
    }
    if (Number(amountSoFar.toFixed(1)) >= Number(amount.toFixed(1))) {
      modInstallments[0].amount -= amount;
    } else {
      const indexText = installmentIndex === 0 ? 'Token' : `installment ${installmentIndex}`;
      const installmentText = `for ${indexText}, Required: ${amount.toFixed(2)} and got ${amountSoFar.toFixed(2)} till ${moment(till)
        .format('DD MMM YY')}`;
      errorMsg = errorMsgs.length === 1
        ? `${errorMsgs[0]} insufficient, ${installmentText}` : `One of ${errorMsgs.join(', ')} conditions is not met, ${installmentText}`;
      valid = false;
      break;
    }
  }
  return {
    valid,
    errorMsg,
  };
};

export const getDateStr = (from, to) => {
  return `${moment(from).format('DD MMM YY')} - ${moment(to).format('DD MMM YY')}`;
};

export const getItineraryParts = (request, bookingId) => {
  const { bookings, itineraries } = request;
  const booking = bookings.find((bookOb) => bookOb._id === bookingId);
  const itinerary = deepClone(itineraries.find((itn) => itn._id === booking.itinerary));
  const itineraryParts = [];
  const partsMap = {};
  for (let i = 0; i < itinerary.itineraryParts.length; i++) {
    const part = itinerary.itineraryParts[i];
    partsMap[part._id] = part;
  }
  for (let i = 0; i < booking.itineraryParts.length; i++) {
    itineraryParts.push(partsMap[booking.itineraryParts[i]]);
  }
  itinerary.suppliers = itinerary.suppliers
    .filter((s) => s.supplier._id === booking?.supplier);
  return {
    itinerary,
    itineraryParts,
  };
};

export const getReachOutInfo = (reachOut) => {
  const {
    contactRequest, fbAdLead,
    intercom, manualRequest,
  } = reachOut;
  if (manualRequest || contactRequest) {
    const {
      places = [], travellers = '-', arrivalDate = '-', datesFlexible = true,
      departureDate = '-', origin = [], tripTypes = [], flightsBooked = false,
      message = '',
    } = manualRequest || contactRequest;
    const { adults, children, infants } = travellers;
    return {
      places: places.map((p) => p.name).join(', '),
      travelMonth: MONTH_MAP[moment(departureDate).month()],
      duration: (moment(arrivalDate).unix() - moment(departureDate).unix()) / 86400,
      origin: origin.map((o) => o.name).join(', '),
      occasion: tripTypes.join(', '),
      travellers: `${adults} adults ${children > 0 ? `${children} children` : ''} ${infants > 0 ? `${infants} infants` : ''}`,
      flightsBooked: flightsBooked ? 'Yes' : 'No',
      datesFlexible: datesFlexible ? 'Yes' : 'No',
      message,
    };
  }
  const finalField = fbAdLead || intercom;
  if (!finalField) {
    return {};
  }
  const {
    duration, travelMonth, occasion, destination,
    flightsBooked = false, datesFlexible = true, numberOfPeople, city = '-',
  } = finalField;
  return {
    places: destination,
    travelMonth,
    duration,
    origin: city,
    occasion,
    travellers: numberOfPeople,
    flightsBooked: flightsBooked ? 'Yes' : 'No',
    datesFlexible: datesFlexible ? 'Yes' : 'No',
  };
};

export const uuid = (length = 6) => {
  const dataStr = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0987654321';
  const dataLen = dataStr.length;
  let uuidStr = '';
  for (let i = 0; i < length; i++) {
    uuidStr += dataStr[Math.floor(Math.random() * dataLen)];
  }
  return uuidStr;
};

export const getInstallmentMap = (paymentModes, orderData = []) => {
  const paymentMap = {};
  for (let i = 0; i < paymentModes.length; i++) {
    paymentMap[paymentModes[i].code] = paymentModes[i];
  }
  const finalInstallments = orderData[0] ? orderData[0].installments : [];
  const templatePaymentMode = {
    value: 'Smart Collect',
    valid: true,
    item: {
      code: 'SMART_COLLECT',
      title: 'Smart Collect',
    },
  };
  const installments = finalInstallments.map((i) => {
    return {
      uniqueId: i.uniqueId || uuid(),
      amount: i.amount / 100,
      amountToBePaid: (i.amountToBePaid || 0) / 100,
      dueDate: moment(moment(i.dueDate).format('DD MMM YY'), 'DD MMM YY')
        .endOf('day').toDate(),
      paid: i.paid,
      referenceNumber: i.referenceNumber || '',
      paymentMode: i.paid ? {
        value: paymentMap[i.paymentMode].title,
        valid: true,
        item: paymentMap[i.paymentMode],
      } : templatePaymentMode,
    };
  });
  return installments;
};

export const validateTripDetails = (details, expert) => {
  const {
    origin,
    places,
    tripDates: { from, to },
    travellers,
    tripTypes,
  } = details;
  let errorMsg = '';
  if (places.items.length === 0) {
    errorMsg = 'Destination is invalid';
  }
  if (!expert?.roles.includes(ROLES.TEAM_LEAD)) {
    if (origin.items.length === 0) {
      errorMsg = 'Origin is invalid';
    } else if (!from || !to) {
      errorMsg = 'Trip dates cannot be empty';
    } else if (moment(from).format('DD MM YYYY') === moment(to).format('DD MM YYYY')) {
      errorMsg = 'Trip dates cannot be same as today';
    } else if (travellers.adults === 0) {
      if (travellers.children === 0 && travellers.infants === 0) {
        errorMsg = 'Travellers cannot be 0';
      } else {
        errorMsg = 'Children and infants cannot travel alone';
      }
    } else if (tripTypes.items.length === 0) {
      errorMsg = 'Trip type cannot be empty';
    }
  }
  return { errorMsg };
};

export const getButtonText = (itineraryActionType, edit, extraText) => {
  let text;
  if (ITINERARY_READ_MODES[itineraryActionType]) {
    text = 'Close';
  } else if (edit) {
    text = `Update ${extraText}`;
  } else {
    text = `Save ${extraText}`;
  }
  return text;
};

export const getCardClass = (type) => {
  if (type === CHANGE_TYPES.DELETE) {
    return 'deletedCard';
  }
  if (type === CHANGE_TYPES.EDIT) {
    return 'editedCard';
  }
  return null;
};

export const getTravellerText = (travellers) => {
  let travellerStr = '';
  if (travellers.adults) {
    travellerStr += `${travellers.adults} adults`;
  }
  if (travellers.children) {
    travellerStr += `${travellers.children} children`;
  }
  if (travellers.infants) {
    travellerStr += `${travellers.infants} infants`;
  }
  return travellerStr;
};

export const shouldShowDiffToggle = (itineraryActionType, originalPart) => {
  return !!((itineraryActionType === 'modify_itinerary'
    || itineraryActionType === 'approve_reject_itinerary'
    || itineraryActionType === 'modify') && originalPart
    && Object.keys(originalPart).length);
};

export const getOriginalPart = (originalParts, part, itineraryActionType, showOverlay) => {
  let originalPart = {};
  if (showOverlay && (itineraryActionType === 'modify' || itineraryActionType === 'modify_itinerary'
    || itineraryActionType === 'approve_reject_itinerary') && part) {
    originalPart = originalParts[part.proposedChange.tag] || {};
  }
  return originalPart;
};

export const convert24to12Hour = (hour) => {
  if (hour < 12) {
    return `${hour.toString().padStart(2, '0')}:00 AM`;
  }
  if (hour === 24) {
    return '12:00 AM';
  }
  if (hour === 12) {
    return '12:00 PM';
  }
  if (hour > 12 && hour - 12 > 0) {
    return `${(hour - 12).toString().padStart(2, '0')}:00 PM`;
  }
  return null;
};

export const calcNTimeSlots = (
  hour,
  count = 3,
  interval = 3,
  startBusiness24Hour = 9,
  endBusiness24Hour = 21
) => {
  const timeSlots = [];
  let inpHour = hour + interval;
  if (inpHour < startBusiness24Hour || inpHour > endBusiness24Hour) {
    return timeSlots;
  }
  for (let i = 0; i < count; ++i) {
    if (startBusiness24Hour <= inpHour && inpHour <= endBusiness24Hour) {
      timeSlots.push(inpHour);
      inpHour += interval;
    }
  }
  return timeSlots.map((item) => convert24to12Hour(item));
};

export const getNextMonthDate = () => {
  const now = new Date();
  let next;
  if (now.getMonth() === 11) {
    next = new Date(now.getFullYear() + 1, 0, now.getDate());
  } else {
    next = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
  }
  return next;
};

export const capitalize = (text = '') => {
  if (!text) {
    return text;
  }
  return `${text.slice(0, 1).toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const cleanInclusionExclusion = (conditions) => {
  const temp = [];
  for (let i = 0; i < conditions.length; i++) {
    const item = conditions[i];
    if (item.text?.trim().length) {
      temp.push({
        uniqueId: item.id,
        text: item.text || '',
      });
    }
  }
  return temp;
};

export const getPartsForPreview = (parts, partType, routes) => {
  let count = 0;
  const previewParts = {};
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].type === partType) {
      count++;
      const routeIndex = parts[i].routeIndex || 0;
      if (previewParts[routeIndex]) {
        previewParts[routeIndex].push({
          ...parts[i],
          itineraryIndex: i,
          route: routes[routeIndex],
        });
      } else {
        previewParts[routeIndex] = [{
          ...parts[i],
          itineraryIndex: i,
          route: routes[routeIndex],
        }];
      }
    }
  }
  return {
    count, previewParts,
  };
};

export const processPricingForSubmission = (pricing = {}) => {
  if (Object.keys(pricing).length === 0) {
    return {};
  }
  let totalSellPrice = Number(pricing.landPackage?.sellPrice || 0) * 100;
  if (pricing?.flights?.sellPrice) {
    totalSellPrice += Number(pricing.flights.sellPrice) * 100;
  }
  if (pricing?.services?.sellPrice) {
    totalSellPrice += Number(pricing.services.sellPrice) * 100;
  }
  let totalCostPrice = 0;
  if (pricing?.landPackage?.costPrice) {
    totalCostPrice += Number(pricing.landPackage.costPrice) * 100;
  }
  if (pricing?.flights?.costPrice) {
    totalCostPrice += Number(pricing.flights.costPrice) * 100;
  }
  if (pricing?.services?.costPrice) {
    totalCostPrice += Number(pricing.services.costPrice) * 100;
  }
  const discountPrice = Number(pricing.discount || 0);
  const finalPricing = {
    landPackage: {
      costPrice: Number(pricing.landPackage?.costPrice || 0) * 100,
      sellPrice: Number(pricing.landPackage?.sellPrice || 0) * 100,
      sellCurrency: '5879fb9a14f89e036eb59550',
      costCurrency: '5879fb9a14f89e036eb59550',
    },
    flights: {
      costPrice: (pricing.flights?.costPrice || 0) * 100,
      sellPrice: (pricing.flights?.sellPrice || 0) * 100,
      sellCurrency: '5879fb9a14f89e036eb59550',
      costCurrency: '5879fb9a14f89e036eb59550',
    },
    services: {
      costPrice: (pricing.services?.costPrice || 0) * 100,
      sellPrice: (pricing.services?.sellPrice || 0) * 100,
      sellCurrency: '5879fb9a14f89e036eb59550',
      costCurrency: '5879fb9a14f89e036eb59550',
    },
    offer: pricing.offer,
    discount: discountPrice * 100,
    totalCostPrice,
    totalSellPrice,
    finalSellPrice: (totalSellPrice - (discountPrice * 100)),
  };
  return finalPricing;
};

export const mapItineraryParts = (processedItn, originalItinerary) => {
  const originalParts = {};
  const newPartsMap = {};
  let finalParts = [];
  for (let i = 0; i < processedItn.itineraryParts.length; i++) {
    const part = processedItn.itineraryParts[i];
    newPartsMap[part._id] = part;
    if (part.status === 'CREATED') {
      const tag = uuid();
      part.proposedChange = {
        changeType: CHANGE_TYPES.ADD,
        tag,
      };
      finalParts.push(part);
    }
  }
  for (let i = 0; i < originalItinerary.itineraryParts.length; i++) {
    const part = originalItinerary.itineraryParts[i];
    console.log('got part', part._id, part);
    const { changeType = CHANGE_TYPES.NO_CHANGE, itineraryPart } = part.proposedChange || {};
    const tag = uuid();
    const proposedChange = {
      changeType,
      tag,
    };
    originalParts[tag] = part;
    part.proposedChange = proposedChange;
    if (changeType === CHANGE_TYPES.DELETE || changeType === CHANGE_TYPES.NO_CHANGE) {
      finalParts.push(part);
    }
    if (changeType === CHANGE_TYPES.EDIT) {
      const newPart = {
        ...newPartsMap[itineraryPart],
        proposedChange,
      };
      finalParts.push(newPart);
    }
  }
  finalParts = finalParts
    .sort((a, b) => moment(a.startTimeStamp).unix() - moment(b.startTimeStamp).unix());
  return { finalParts, originalParts };
};
