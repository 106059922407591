import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/styles/withStyles';
import createStyles from '@material-ui/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Visibility from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Header from 'UI/header';
import Confirmation from 'UI/confirmation';
import StayPreview from 'components/itineraryMaker/stayPreview';
import ActivityPreview from 'components/itineraryMaker/activityPreview';
import InclusionExclusion from 'components/itineraryMaker/inclusionExclusion';
import ServicesPreview from 'components/itineraryMaker/servicesPreview';
import TransfersPreview from 'components/itineraryMaker/transfersPreview';
import FlightsPreview from 'components/itineraryMaker/flightsPreview';
import Pricing from 'components/itineraryMaker/pricing';
import Installments from 'components/itineraryMaker/installments';
import ItineraryUser from 'components/itineraryMaker/itineraryUser';
import ExtraServices from 'components/itineraryMaker/extraServices';
import ModifyConfirmation from 'components/itineraryMaker/modifyConfirmation';
import Supplier from 'components/itineraryMaker/supplier';
import RejectReason from 'components/dashboard/rejectReason';
import ItineraryNotes from 'components/itineraryMaker/itineraryNotes';
import Travellers from 'components/itineraryMaker/travellers';
import {
  omit,
  processRequirement,
  convertRequirement,
  getItineraryParts,
  validateEmail,
  getInstallmentMap,
  uuid,
  validateTripDetails,
  deepClone,
  isEqual,
  mapItineraryParts,
  cleanInclusionExclusion,
} from 'utils/common';
import {
  ITINERARY_ACTIONS_TYPES,
  ITINERARY_READ_MODES,
  ROLES,
  CHANGE_TYPES,
} from 'utils/consts';
import Button from 'UI/button';
import RouteSelection from './routeSelection';
import AddTripRequirement from './addTripRequirement';
import SendItinerary from './sendItinerary';

class ItineraryDetails extends Component {
  constructor(props) {
    super(props);
    this.originalItinerary = {};
    this.originalParts = {};
    this.originalInstallments = [];
    const {
      request, itinerary, activeBookingId,
      itineraryActionType, expert, meta,
    } = this.props;
    let changes = {};
    let itineraryData = {};
    if (request.primaryCustomerData) {
      changes = this.getRequestDetails(request, itinerary);
    }
    if (itineraryActionType === 'view_booking' || itineraryActionType === 'accept_booking'
      || itineraryActionType === 'confirm_booking') {
      const {
        itinerary: itn,
        itineraryParts: iParts,
      } = getItineraryParts(request, activeBookingId);
      const modItn = {
        ...itn,
        itineraryParts: iParts,
      };
      changes = {
        ...changes,
        ...this.processItineraryPayload(modItn, request.orderInfo?.orderData || [], false),
      };
      itineraryData = this.getItineraryData(modItn);
    }
    if (itineraryActionType === 'edit' || itineraryActionType === 'request_booking'
      || itineraryActionType === 'create_ops_itinerary' || itineraryActionType === 'modify_itinerary'
      || itineraryActionType === 'modify_itinerary_tech') {
      const processedItn = this.processItineraryPayload(itinerary,
        request.orderInfo?.orderData || [], false);
      if (itineraryActionType === 'modify_itinerary') {
        // marking itinerary parts
        this.originalItinerary = deepClone(processedItn);
        for (let i = 0; i < this.originalItinerary.itineraryParts.length; i++) {
          const tag = uuid();
          const proposedChange = {
            tag,
            changeType: CHANGE_TYPES.NO_CHANGE,
          };
          this.originalItinerary.itineraryParts[i].proposedChange = proposedChange;
          processedItn.itineraryParts[i].proposedChange = proposedChange;
          this.originalParts[tag] = this.originalItinerary.itineraryParts[i];
        }
        this.originalInstallments = this.originalItinerary.installments;
      }
      changes = {
        ...changes,
        ...processedItn,
      };
      itineraryData = this.getItineraryData(itinerary);
    }
    if (itineraryActionType === 'approve_reject_itinerary') {
      this.originalItinerary = this.processItineraryPayload(itinerary,
        request.orderInfo?.orderData || [], true);
      this.originalInstallments = this.originalItinerary.installments;
      // marking itinerary parts
      const processedItn = this.processItineraryPayload(itinerary.proposedItinerary, [{
        installments: itinerary.proposedItinerary.proposedOrderInfo.installments,
      }], false);
      const {
        finalParts,
        originalParts,
      } = mapItineraryParts(processedItn, this.originalItinerary);
      this.originalParts = originalParts;
      processedItn.itineraryParts = finalParts;
      changes = {
        ...changes,
        requirement: processRequirement(itinerary.proposedItinerary.requirement, meta.tripTypes),
        ...processedItn,
      };
      itineraryData = this.getItineraryData(itinerary.proposedItinerary);
    }
    if (itineraryActionType === 'generate_voucher') {
      const modItinerary = this.processRequestForVoucher(request);
      const voucherChanges = {
        groundSupportNumber: request.voucherDetails?.supportNumbers?.ground || '',
        groundSupport: request.voucherDetails.groundSupport || [],
        notes: request.voucherDetails?.notes || [{
          id: uuid(),
          text: '',
        }],
        travellerDetails: request.voucherDetails?.travellerDetails || {},
        otherDocuments: request.voucherDetails?.otherDocuments || [],
      };
      changes = {
        ...changes,
        ...voucherChanges,
        ...this.processItineraryPayload(modItinerary, request.orderInfo?.orderData || [], false),
      };
    }
    if (itineraryActionType === 'view_itinerary') {
      changes = {
        ...changes,
        ...this.processItineraryPayload(itinerary, request.orderInfo?.orderData || [], false),
      };
      itineraryData = this.getItineraryData(itinerary);
    }
    this.sections = ['Request Info', 'Itinerary', 'Pricing & Installments'];
    this.tabs = {
      'Request Info': ['Customer itinerary', 'User details', 'Trip details'],
      Itinerary: ['Suppliers', 'Route', 'Stays', 'Experiences', 'Transfers', 'Flights',
        'Services', 'Exclusions & Inclusions'],
      'Pricing & Installments': ['Pricing', 'Installments'],
    };
    let activeTab = 'Customer itinerary';
    let activeSection = 'Request Info';
    // we are creating or modifying itinerary pricing wont be shown
    const shouldShowPricing = {
      request_booking: true,
      modify_itinerary: true,
      modify_itinerary_tech: true,
      approve_reject_itinerary: true,
    };
    if (!shouldShowPricing[itineraryActionType]) {
      this.sections.pop();
    }
    if (itineraryActionType === 'edit') {
      activeTab = 'Customer itinerary';
      activeSection = 'Itinerary';
      this.sections = ['Itinerary'];
      this.tabs = {
        Itinerary: [...this.tabs['Request Info'], ...this.tabs.Itinerary],
      };
    } else if (ITINERARY_READ_MODES[itineraryActionType] && itineraryActionType !== 'approve_reject_itinerary') {
      activeTab = 'Trip details';
      activeSection = 'Itinerary';
      this.sections = ['Itinerary'];
      this.tabs = {
        Itinerary: ['Trip details', ...this.tabs.Itinerary],
      };
      if (itineraryActionType === 'view_itinerary') {
        activeTab = 'User details';
        this.tabs.Itinerary = ['User details', ...this.tabs.Itinerary, 'Pricing'];
      }
    } else if (itineraryActionType === 'create') {
      activeTab = 'User details';
      this.tabs['Request Info'] = this.tabs['Request Info'].filter((r) => r !== 'Customer itinerary');
    } else if (itineraryActionType === 'create_ops_itinerary') {
      activeTab = 'User details';
      activeSection = 'Request Info';
      this.sections = ['Request Info', 'Itinerary', 'Pricing & Installments'];
      this.tabs['Request Info'].shift();
    }
    if (expert.roles.includes(ROLES.EXPERT)) {
      // remove supplier tab from expert from them only team lead should do
      this.tabs.Itinerary = this.tabs.Itinerary.filter((t) => t !== 'Suppliers');
    }
    // if not filtration flights and services wont be shown
    const isOPTlAdmin = expert.roles.includes(ROLES.OPERATION)
      || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.ADMIN);
    if (!isOPTlAdmin) {
      this.tabs.Itinerary = this.tabs.Itinerary
        .filter((tab) => (tab !== 'Flights' && tab !== 'Services'));
    }
    if (itineraryActionType === 'request_booking') {
      this.tabs.Itinerary.push('Extras');
    }
    if (itineraryActionType === 'modify_itinerary' || itineraryActionType === 'approve_reject_itinerary') {
      this.tabs['Pricing & Installments'].push('Confirmation');
    }
    if (itineraryActionType === 'accept_booking') {
      activeTab = 'Suppliers';
      this.tabs.Itinerary = ['Suppliers'];
    }
    if (itineraryActionType === 'confirm_booking') {
      activeTab = 'Suppliers';
      this.tabs.Itinerary = ['Suppliers', 'Stays'];
    }
    if (itineraryActionType === 'generate_voucher') {
      activeTab = 'User details';
      this.tabs['Request Info'].shift();
      // this.tabs['Request Info'].push('Travellers');
      this.tabs.Itinerary = this.tabs.Itinerary.filter((t) => t !== 'Services' && t !== 'Suppliers');
      this.tabs.Itinerary.push('Notes');
    }
    this.state = {
      activeSection,
      activeTab,
      itineraryData,
      deleteConfirmation: false,
      deleteIndex: -1,
      identifier: -1,
      routes: [{
        id: uuid(),
        place: {
          value: '',
          valid: false,
          item: {},
        },
        dates: {},
      }],
      itineraryParts: [],
      suppliers: [],
      exclusions: [{
        id: uuid(),
        text: '',
      }],
      inclusions: [{
        id: uuid(),
        text: '',
      }],
      pricing: {
        landPackage: this.getPricingTemplate(),
        flights: this.getPricingTemplate(),
        services: this.getPricingTemplate(),
        offer: '',
        discount: '0',
      },
      installments: [],
      tasksRequested: {},
      modifyReason: '',
      errorMsg: {},
      // user details
      userDetails: {},
      documents: request.documents || [],
      // trip details
      requirement: {
        origin: {
          value: '',
          valid: false,
          items: [],
        },
        places: {
          value: '',
          valid: false,
          items: [],
        },
        tripDate: '',
        tripDates: {},
        duration: '',
        flightsBooked: false,
        isFlexible: false,
        travellers: {
          adults: 0,
          children: 0,
          infants: 0,
        },
        tripTypes: {
          value: '',
          valid: false,
          items: [],
        },
      },
      travellerDetails: {},
      otherDocuments: [],
      notes: [{
        id: uuid(),
        text: '',
      }],
      refundAmount: 0,
      groundSupportNumber: '',
      groundSupport: [],
      rejectionReasonDialog: false,
      b2bPartnerOrderAmount: '',
      ...changes,
    };
    this.itineraryMode = ITINERARY_READ_MODES[itineraryActionType] ? 'view' : 'edit';
    if ((ITINERARY_READ_MODES[itineraryActionType] && changes.routes.length === 0)
      || (itineraryActionType === 'create_ops_itinerary')) {
      const tabsToHide = {
        Suppliers: true,
        Route: true,
        Stays: true,
        Experiences: true,
        Transfers: true,
      };
      this.state.activeTab = 'Trip details';
      this.tabs.Itinerary = this.tabs.Itinerary.filter((tab) => !tabsToHide[tab]);
    }
    this.tabsToCheckChange = {
      Suppliers: true,
      Route: true,
      Stays: true,
      Experiences: true,
      Transfers: true,
      Flights: true,
      Services: true,
      'Exclusions & Inclusions': true,
      Pricing: true,
      Installments: true,
    };
    this.partTypeMap = {
      Stays: 'STAY',
      Services: 'SERVICE',
      Flights: 'FLIGHT',
      Transfers: 'TRANSFER',
      Experiences: 'ACTIVITY',
    };
    this.setHeading();
  }

  setHeading = () => {
    const { itineraryActionType, request } = this.props;
    this.heading = 'Itinerary details';
    if (itineraryActionType === 'edit') {
      this.heading = 'Edit itinerary';
    } else if (itineraryActionType === 'request_booking') {
      this.heading = 'Request booking';
    } else if (itineraryActionType === 'view_booking') {
      this.heading = 'View booking details';
    } else if (itineraryActionType === 'accept_booking') {
      this.heading = 'Accept booking';
    } else if (itineraryActionType === 'confirm_booking') {
      this.heading = 'Confirm booking';
    } else if (itineraryActionType === 'create' || itineraryActionType === 'create_ops_itinerary') {
      this.heading = 'Create Itinerary';
    } else if (itineraryActionType === 'modify_itinerary') {
      this.heading = 'Modify itinerary';
    } else if (itineraryActionType === 'view_itinerary') {
      this.heading = 'View itinerary';
    } else if (itineraryActionType === 'modify_itinerary_tech') {
      this.heading = 'Modify itinerary (Tech)';
    } else if (itineraryActionType === 'approve_reject_itinerary') {
      this.heading = 'Approve / Reject itinerary';
    } else if (itineraryActionType === 'generate_voucher' && !request.voucherDetails?.voucherStatus) {
      this.heading = 'Generate Voucher';
    } else if (itineraryActionType === 'generate_voucher' && request.voucherDetails?.voucherStatus) {
      this.heading = 'Edit Voucher';
    }
  };

  getRequestDetails = (request, itinerary) => {
    const { meta } = this.props;
    const { primaryCustomerData, requirement } = request;
    let finalRequirement = requirement;
    if (itinerary && itinerary.requirement && itinerary.requirement.origin) {
      finalRequirement = itinerary.requirement;
    }
    const {
      email, firstName, lastName,
      phone, phoneDialCode, whatsApp, whatsAppDialCode,
    } = primaryCustomerData;
    const userDetails = {
      email,
      firstName,
      lastName,
      whatsApp,
      whatsAppDialCode: {
        value: whatsAppDialCode,
        valid: true,
      },
      phone,
      phoneDialCode: {
        value: phoneDialCode,
        valid: true,
      },
    };
    return {
      userDetails,
      requirement: processRequirement(finalRequirement, meta.tripTypes),
    };
  };

  processRequestForVoucher = (request) => {
    const { itineraries, voucherDetails } = request;
    const combinedItinerary = {
      routes: [],
      suppliers: [],
      itineraryParts: [],
      pricing: {
        landPackage: {
          sellPrice: 0,
          costPrice: 0,
        },
        flights: {
          sellPrice: 0,
          costPrice: 0,
        },
        services: {
          sellPrice: 0,
          costPrice: 0,
        },
        discount: 0,
        offer: '',
      },
      inclusions: [],
      exclusions: [],
    };
    for (let i = 0; i < itineraries.length; i++) {
      if (itineraries[i].status === 'APPROVED') {
        combinedItinerary.suppliers.push(...itineraries[i].suppliers);
        combinedItinerary.itineraryParts.push(...itineraries[i].itineraryParts);
        combinedItinerary.routes.push(...itineraries[i].routes);
        combinedItinerary.inclusions.push(...itineraries[i].inclusions);
        combinedItinerary.exclusions.push(...itineraries[i].exclusions);
        const oldPricing = { ...combinedItinerary.pricing };
        combinedItinerary.pricing = {
          landPackage: {
            costPrice: oldPricing.landPackage.costPrice
              + itineraries[i].pricing.landPackage.costPrice,
            sellPrice: oldPricing.landPackage.sellPrice
              + itineraries[i].pricing.landPackage.sellPrice,
          },
          flights: {
            costPrice: oldPricing.flights.costPrice + itineraries[i].pricing.flights?.costPrice
              || 0,
            sellPrice: oldPricing.flights.sellPrice + itineraries[i].pricing.flights?.sellPrice
              || 0,
          },
          services: {
            costPrice: oldPricing.services.costPrice + itineraries[i].pricing.services?.costPrice
              || 0,
            sellPrice: oldPricing.services.sellPrice + itineraries[i].pricing.services?.sellPrice
              || 0,
          },
          offer: '',
          discount: oldPricing.discount + itineraries[i].pricing.discount,
        };
      }
    }
    combinedItinerary.itineraryParts.push(...voucherDetails.customerItineraryParts);
    return combinedItinerary;
  };

  processItineraryPayload = (respItinerary, orderData = [], isOriginal) => {
    const {
      meta: {
        serviceTypes = [],
        transfers = [],
        paymentMode = [],
      },
      itineraryActionType,
    } = this.props;

    const transferModeMap = {};
    for (let i = 0; i < transfers.length; i++) {
      transferModeMap[transfers[i].code] = transfers[i];
    }
    const serviceTypeMap = {};
    for (let i = 0; i < serviceTypes.length; i++) {
      serviceTypeMap[serviceTypes[i].code] = serviceTypes[i].title;
    }
    const itinerary = {};
    const suppliers = respItinerary.suppliers || [];
    itinerary.suppliers = suppliers.map((supplier) => ({
      ...supplier,
      costPrice: supplier.costPrice / 100,
    }));
    const routeMap = {};
    itinerary.routes = (respItinerary.routes || []).map((route) => {
      const routeItem = {
        id: uuid(),
        place: {
          value: route?.place?.name,
          valid: true,
          item: route.place || {},
        },
        dates: {
          from: moment(route.startTimeStamp)
            .toDate(),
          to: moment(route.endTimeStamp)
            .toDate(),
        },
      };
      routeMap[route?.place?._id] = routeItem;
      return routeItem;
    });
    // considering removed routes as well for sake of stays
    if (!isOriginal && itineraryActionType === 'approve_reject_itinerary') {
      for (let i = 0; i < this.originalItinerary.routes.length; i++) {
        const oldRoute = this.originalItinerary.routes[i];
        if (!routeMap[oldRoute.place.item.id]) {
          routeMap[oldRoute.place.item._id] = oldRoute;
        }
      }
    }
    itinerary.inclusions = (respItinerary.inclusions || [])
      .map((i) => ({
        text: i.text,
        id: i.uniqueId,
        _id: i._id,
      }));
    itinerary.exclusions = (respItinerary.exclusions || [])
      .map((e) => ({
        text: e.text,
        id: e.uniqueId,
        _id: e._id,
      }));
    itinerary.installments = getInstallmentMap(paymentMode, orderData);
    const {
      landPackage, flights, services,
      offer = '', discount = 0,
    } = (respItinerary.pricing || {});
    const fillPricing = (destination, source = {}, field) => {
      const modDest = { ...destination };
      modDest[field] = {
        costPrice: source.costPrice / 100 || 0,
        sellPrice: source.sellPrice / 100 || 0,
        costCurrency: {
          value: source.costCurrency ? source.costCurrency.name : 'INR',
          valid: true,
          item: source.costCurrency || { _id: 'some_id' },
        },
      };
      return modDest;
    };
    let pricing = {};
    pricing = fillPricing(pricing, landPackage, 'landPackage');
    pricing = fillPricing(pricing, flights, 'flights');
    pricing = fillPricing(pricing, services, 'services');
    pricing.offer = offer;
    pricing.discount = discount / 100;
    itinerary.pricing = pricing;
    if (!respItinerary.itineraryParts || respItinerary.itineraryParts.length === 0) {
      return {
        ...itinerary,
        itineraryParts: [],
      };
    }
    const parts = [];
    for (let i = 0; i < respItinerary.itineraryParts.length; i++) {
      const part = respItinerary.itineraryParts[i];
      let changes = {};
      const dataFieldName = `${part.type.toLowerCase()}Data`;
      if (part.type === 'STAY') {
        changes = {
          route: { ...routeMap[part[dataFieldName].routeId] },
          transferMode: part[dataFieldName].transferMode?.map((tm) => transferModeMap[tm]) || [],
        };
      }
      if (part.type === 'SERVICE' || part.type === 'FLIGHT') {
        changes = {
          costPrice: part.costPrice,
          sellPrice: part.sellPrice,
        };
      }
      if (part.type === 'SERVICE') {
        part[dataFieldName].serviceType = serviceTypeMap[part[dataFieldName].serviceType];
      }
      if (part.type === 'ACTIVITY') {
        if (part.activityData.transferMode) {
          changes.transferMode = transferModeMap[part.activityData.transferMode];
        }
        // FIXME: get it corrected from backend
        if (part.activityData.experience?.experience) {
          changes = {
            ...part,
            ...part.activityData,
            experience: {
              ...part.activityData.experience.experience,
              _id: part.activityData.experience._id,
            },
            ...changes,
          };
        } else {
          changes = {
            ...part,
            ...part.activityData,
            ...changes,
          };
        }
      }
      // delete part.supplier;
      changes.supplier = {
        value: (part.supplier || '').name,
        valid: true,
        item: { ...part.supplier },
      };
      if (itineraryActionType === 'approve_reject_itinerary') {
        changes.proposedChange = part.proposedChange || {};
      }
      if (itineraryActionType === 'generate_voucher') {
        changes.source = part.source;
      }
      parts.push({
        ...part[dataFieldName],
        _id: part._id,
        startTimeStamp: part.startTimeStamp,
        endTimeStamp: part.endTimeStamp,
        status: part.status,
        type: part.type,
        created_at: part.created_at,
        ...changes,
      });
    }
    itinerary.itineraryParts = parts;
    itinerary.modifyReason = respItinerary.modifyReason || '';
    itinerary.identifier = respItinerary.identifier;
    return itinerary;
  };

  getPricingTemplate = () => {
    return {
      costPrice: 0,
      sellPrice: 0,
      costCurrency: {
        value: 'INR',
        valid: true,
        item: { _id: '5879fb9a14f89e036eb59550' },
      },
    };
  };

  getItineraryData = (itn) => {
    if (!itn.document && !itn.link) {
      return {
        modified: false,
        link: '',
        fileName: '',
      };
    }
    if (itn.link) {
      return {
        modified: false,
        link: itn.link,
        fileName: '',
      };
    }
    return {
      modified: false,
      link: itn.document.link,
      fileName: itn.document.fileName,
      _id: itn.document._id,
    };
  };

  handleSectionChange = (section) => {
    const { activeSection, activeTab } = this.state;
    if (activeSection !== section) {
      const scs = Object.keys(this.tabs);
      const targetIndex = scs.indexOf(section);
      const currentIndex = scs.indexOf(activeSection);
      const changes = {};
      if (section !== 'Confirmation') {
        changes.activeTab = this.tabs[section][0];
      }
      if (targetIndex < currentIndex) {
        this.setState({ activeSection: section, ...changes });
        return;
      }
      const { valid, errorMsg } = this.validateTab(activeTab);
      if (!valid) {
        this.setState({ errorMsg });
        return;
      }
      this.setState({ activeSection: section, ...changes });
    }
  };

  handleTabChange = (tab) => {
    const { activeSection, activeTab } = this.state;
    if (tab !== activeTab) {
      const targetIndex = this.tabs[activeSection].indexOf(tab);
      const currentIndex = this.tabs[activeSection].indexOf(activeTab);
      if (targetIndex > currentIndex) {
        // validating all tabs in between as well
        for (let i = currentIndex; i < targetIndex; i++) {
          const { valid, errorMsg } = this.validateTab(this.tabs[activeSection][i]);
          if (!valid) {
            this.setState({
              errorMsg,
              activeTab: this.tabs[activeSection][i],
            });
            return;
          }
        }
      }
      this.setState({ activeTab: tab });
    }
  };

  getPartIndexFromTag = (tag) => {
    let foundIndex = -1;
    for (let i = 0; i < this.originalItinerary.itineraryParts.length; i++) {
      if (this.originalItinerary.itineraryParts[i].proposedChange.tag === tag) {
        foundIndex = i;
        break;
      }
    }
    return foundIndex;
  };

  handleItemChange = (action, index, value) => {
    const { itineraryActionType } = this.props;
    const { itineraryParts } = this.state;
    if (this.itineraryMode === 'view' && itineraryActionType !== 'confirm_booking') {
      return;
    }
    let modParts = [...itineraryParts];
    const finalValue = { ...value };
    if (action === 'add') {
      let finalIndex = -1;
      const requestedTime = moment(value.startTimeStamp).unix();
      for (let i = 0; i < modParts.length; i++) {
        const item = modParts[i];
        const time = moment(item.startTimeStamp).unix();
        if (requestedTime <= time) {
          finalIndex = i;
          break;
        }
      }
      if (itineraryActionType === 'modify_itinerary') {
        finalValue.proposedChange = {
          changeType: CHANGE_TYPES.ADD,
          tag: uuid(),
        };
      }
      if (finalIndex === -1) {
        modParts.push(finalValue);
      } else {
        modParts = [
          ...modParts.slice(0, finalIndex),
          finalValue,
          ...modParts.slice(finalIndex),
        ];
      }
    } else if (action === 'edit') {
      if (itineraryActionType === 'modify_itinerary') {
        // mark update in original as well
        const currTag = finalValue.proposedChange.tag;
        const foundIndex = this.getPartIndexFromTag(currTag);
        if (currTag !== CHANGE_TYPES.DELETE) {
          if (foundIndex !== -1) {
            this.originalItinerary.itineraryParts[foundIndex].proposedChange = {
              tag: currTag,
              changeType: CHANGE_TYPES.EDIT,
            };
            finalValue.proposedChange.changeType = CHANGE_TYPES.EDIT;
          }
        }
      }
      modParts[index] = finalValue;
    } else if (action === 'remove') {
      this.setState({
        deleteConfirmation: true,
        deleteIndex: index,
      });
    }
    this.setState({ itineraryParts: modParts });
  };

  // handleDateSelection = (date, dateStr) => {
  //   const { requirement } = this.state;
  //   const { from, to } = date;
  //   let changes = {};
  //   if (from && to) {
  //     changes = {
  //       duration: `${moment(to)
  //         .diff(moment(from)) / 86400000}`,
  //     };
  //   }
  //   const modRequirement = {
  //     ...requirement,
  //     tripDate: dateStr,
  //     ...changes,
  //     tripDates: date,
  //   };
  //   this.setState({ requirement: modRequirement });
  // };

  // handleTripItemSelection = (field, accessor, item) => {
  //   const { requirement } = this.state;
  //   const { [field]: stateField } = requirement;
  //   let modField;
  //   if (stateField.items) {
  //     const iIndex = stateField.items.findIndex((i) => i[accessor] === item[accessor]);
  //     if (iIndex === -1) {
  //       modField = {
  //         value: '',
  //         valid: true,
  //         items: [item, ...stateField.items],
  //       };
  //     } else {
  //       modField = stateField;
  //     }
  //   } else {
  //     modField = {
  //       value: item[accessor],
  //       valid: true,
  //       item,
  //     };
  //   }
  //   const modRequirement = {
  //     ...requirement,
  //     [field]: modField,
  //   };
  //   this.setState({ requirement: modRequirement });
  // };

  // handleSearchChange = (field, searchType, value) => {
  //   const { getSuggestions } = this.props;
  //   const { requirement } = this.state;
  //   let type = 'countries';
  //   let extras = {};
  //   if (searchType === 'cities') {
  //     type = 'cities';
  //     extras = { includeAddressTypes: ['locality'] };
  //   } else if (searchType === 'places') {
  //     type = 'destinations';
  //     extras = { includeAddressTypes: ['locality', 'country', 'administrative_area_level_1'] };
  //   }
  //   getSuggestions(type, value, extras);
  //   const modRequirement = { ...requirement };
  //   const { [field]: stateField } = modRequirement;
  //   modRequirement[field] = {
  //     ...stateField,
  //     value,
  //   };
  //   this.setState({ requirement: modRequirement });
  // };

  // removeHandler = (field, accessor, item) => {
  //   const { requirement } = this.state;
  //   const modRequirement = { ...requirement };
  //   const { [field]: varField } = modRequirement;
  //   const modField = { ...varField };
  //   modField.items = modField.items.filter((d) => d[accessor] !== item[accessor]);
  //   modRequirement[field] = modField;
  //   this.setState({ requirement: modRequirement });
  // };

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object' && itm.value !== undefined) {
      this.setState({
        [field]: {
          ...itm,
          value,
          valid: false,
        },
      });
    } else {
      this.setState({ [field]: value });
    }
  };

  handleRequirementChange = (tripDetails) => {
    this.setState({ requirement: tripDetails });
  };

  checkSupplierMismatch = () => {
    const { suppliers, itineraryParts } = this.state;
    let errorMsg = '';
    const supplierMap = {};
    for (let i = 0; i < suppliers.length; i++) {
      supplierMap[suppliers[i].supplier._id] = {
        hasPart: false,
        name: suppliers[i].supplier.name,
      };
    }
    for (let i = 0; i < itineraryParts.length; i++) {
      const part = itineraryParts[i];
      // skip check if part is deleted
      if (part.proposedChange?.changeType
        && part.proposedChange?.changeType === CHANGE_TYPES.DELETE) {
        continue;
      }
      if (supplierMap[part.supplier.item._id]) {
        supplierMap[part.supplier.item._id].hasPart = true;
      } else if (part.type !== 'FLIGHT' && part.type !== 'SERVICE') {
        errorMsg = 'Some itinerary parts are tagged to supplier which are not present';
        break;
      }
    }
    if (errorMsg) {
      return errorMsg;
    }
    const keys = Object.keys(supplierMap);
    for (let i = 0; i < keys.length; i++) {
      if (!supplierMap[keys[i]].hasPart) {
        errorMsg = 'Suppliers found without itinerary part';
        break;
      }
    }
    return errorMsg;
  };

  prevHandler = () => {
    const { activeTab, activeSection } = this.state;
    const currIndex = this.tabs[activeSection].indexOf(activeTab);
    if (currIndex !== 0) {
      this.setState({ activeTab: this.tabs[activeSection][currIndex - 1] });
    }
  };

  nextHandler = () => {
    const { itineraryActionType, onClose } = this.props;
    const { activeSection, activeTab } = this.state;
    const { valid, errorMsg } = this.validateTab(activeTab);
    if (!valid) {
      this.setState({ errorMsg });
      return;
    }
    const sectionIndex = this.sections.indexOf(activeSection);
    const tabIndex = this.tabs[activeSection].indexOf(activeTab);
    if (tabIndex === this.tabs[activeSection].length - 1) {
      // last tab - check for next section else submit itinerary
      if (sectionIndex === this.sections.length - 1) {
        if (itineraryActionType === 'view_booking') {
          onClose();
          return;
        }
        const supplierError = this.checkSupplierMismatch();
        if (!supplierError) {
          this.submitHandler();
        } else {
          const modError = { ...errorMsg };
          modError[activeTab] = supplierError;
          this.setState({ errorMsg: modError });
        }
      } else {
        const newSection = this.sections[sectionIndex + 1];
        this.setState({
          activeTab: this.tabs[newSection][0],
          activeSection: newSection,
        });
      }
    } else {
      // tabs are still left
      this.setState({ activeTab: this.tabs[activeSection][tabIndex + 1] });
    }
  };

  processPartsForSubmission = (itineraryParts) => {
    const { meta: { serviceTypes = [] }, itineraryActionType } = this.props;
    let includesLandPackage = false;
    const serviceTypeMap = {};
    for (let i = 0; i < serviceTypes.length; i++) {
      serviceTypeMap[serviceTypes[i].title] = serviceTypes[i].code;
    }
    const parts = itineraryParts.map((i) => {
      let changes = {};
      if (i.type === 'STAY' || i.type === 'TRANSFER' || i.type === 'ACTIVITY') {
        includesLandPackage = true;
      }
      const part = { ...i };
      if (i.type === 'STAY') {
        part.routeId = part.route.place.item._id;
        part.stay = part.stay._id;
        part.transferMode = [...part.transferMode].map((tm) => tm.code);
        delete part.route;
      }
      if (i.type === 'SERVICE') {
        part.supplierCurrency = part.supplier.item.currency
          ? part.supplier.item.currency._id : '';
        part.conversionRate = part.conversionRate || 100;
        changes = {
          costPrice: part.costPrice,
          sellPrice: part.sellPrice,
          sellCurrency: part.supplier.item.currency._id,
          conversionRate: 100,
        };
        if (part.serviceType === 'Travel Insurance') {
          part.travelInsuranceData = {
            pax: part.travelInsuranceData.pax,
            documents: part.travelInsuranceData.documents,
            description: part.travelInsuranceData.description,
          };
        } else if (part.serviceType === 'Forex') {
          part.forexData = {
            targetAmount: part.forexData.targetAmount,
            sourceCurrency: part.forexData.sourceCurrency._id,
            targetCurrency: part.forexData.targetCurrency._id,
          };
        } else if (part.serviceType === 'Visa') {
          part.visaData = {
            pax: part.visaData.pax,
            country: part.visaData.country._id,
          };
          part.serviceType = serviceTypeMap[part.serviceType];
        }
      }
      if (i.type === 'FLIGHT') {
        part.supplierCurrency = part.supplier.item.currency
          ? part.supplier.item.currency._id : '';
        part.conversionRate = part.conversionRate || 100;
        part.departureFlightData.from = part.departureFlightData?.from?._id || '';
        part.departureFlightData.to = part.departureFlightData?.to?._id || '';
        if (part.flightType === 'Round trip') {
          part.returnFlightData.from = part.returnFlightData.from._id;
          part.returnFlightData.to = part.returnFlightData.to._id;
        }
        changes = {
          costPrice: part.costPrice,
          sellPrice: part.sellPrice,
          sellCurrency: part.supplier.item.currency?._id || '',
          conversionRate: 100,
          source: part.source,
        };
      }
      if (i.type === 'TRANSFER') {
        part.from = part.from?._id ? part.from?._id : part.transfers[0]?._id;
        part.to = part.to?._id ? part.to?._id : part.transfers[0]?._id;
      }
      if (i.type === 'ACTIVITY' && part && part.location && part.location._id) {
        part.location = part.location._id;
      }
      const partSupplier = { ...part.supplier };
      delete part.supplier;
      const type = part.type;
      delete part.type;
      if (itineraryActionType === 'modify_itinerary') {
        changes.proposedChange = part.proposedChange;
      }
      return {
        _id: part._id,
        startTimeStamp: moment(i.startTimeStamp).toISOString(),
        endTimeStamp: moment(i.endTimeStamp || i.startTimeStamp).toISOString(),
        type,
        status: part.status,
        created_at: part.created_at,
        supplier: partSupplier.item._id,
        supplierCurrency: part.supplierCurrency,
        ...changes,
        [`${i.type.toLowerCase()}Data`]: omit(part,
          ['_id', 'startTimeStamp', 'endTimeStamp', 'type',
            'status', 'created_at', 'proposedChange', 'source']),
      };
    });
    return {
      parts,
      includesLandPackage,
    };
  };

  submitHandler = () => {
    const {
      request,
      onCreate,
      itineraryActionType,
      activeBookingId,
      onBookingModify,
      onClose,
    } = this.props;
    if (itineraryActionType === 'accept_booking' || itineraryActionType === 'confirm_booking') {
      const { suppliers } = this.state;
      const { _id } = activeBookingId;
      const {
        supplier, costPrice, currency, quotation,
        supplierRefNum, conversionRate = 1,
        // customerRefNum,
      } = suppliers[0];
      const params = {
        supplier: {
          supplier: supplier._id,
          costPrice: Number(costPrice) * 100,
          quotation,
          supplierRefNum,
          // customerRefNum: itineraryActionType === 'confirm_booking'
          //   ? customerRefNum.split(',') : undefined,
          currency: currency._id,
          conversionRate,
        },
        bookingId: _id,
      };
      if (itineraryActionType === 'confirm_booking') {
        const { itineraryParts } = this.state;
        const { parts } = this.processPartsForSubmission(deepClone(itineraryParts));
        params.itineraryParts = parts;
      }
      onBookingModify(params);
      return;
    }
    if (this.itineraryMode === 'view') {
      onClose();
      return;
    }
    const stateData = deepClone({ ...this.state });
    if (stateData.itineraryParts.length === 0) {
      const { errorMsg, activeTab } = this.state;
      const modErrorMsg = { ...errorMsg };
      modErrorMsg[activeTab] = 'Cannot have itinerary without itineraryParts';
      this.setState({ errorMsg: modErrorMsg });
      return;
    }
    const params = omit(stateData,
      ['errorMsg', 'activeTab', 'activeSection', 'requirement',
        'deleteIndex', 'deleteConfirmation', 'rejectionReasonDialog']);
    if (itineraryActionType === 'request_booking') {
      params.tasksRequested = Object.keys(params.tasksRequested);
    } else {
      delete params.tasksRequested;
    }
    params.refundAmount *= 100;
    const userDetails = { ...stateData.userDetails };
    userDetails.phoneDialCode = userDetails.phoneDialCode.value;
    userDetails.whatsAppDialCode = userDetails.whatsAppDialCode.value;
    params.primaryCustomerData = userDetails;
    params.requirement = convertRequirement(stateData.requirement);
    const { parts, includesLandPackage } = this.processPartsForSubmission(params.itineraryParts);
    params.itineraryParts = parts;
    if (itineraryActionType !== 'generate_voucher') {
      delete params.travellerDetails;
      delete params.otherDocuments;
      delete params.groundSupportNumber;
      delete params.groundSupport;
    } else {
      const customerItineraryParts = [];
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (part.source === 'CUSTOMER') {
          const modPart = { ...part };
          delete modPart.supplierCurrency;
          delete modPart.sellCurrency;
          customerItineraryParts.push(modPart);
        }
      }
      const voucherParams = {
        voucherDetails: {
          supportNumbers: {
            ground: params.groundSupportNumber,
            flynote: '+918061930480',
            expert: request.expert.mobile,
          },
          groundSupport: params.groundSupport,
          notes: params.notes,
          travellerDetails: params.travellerDetails,
          otherDocuments: params.otherDocuments,
          customerItineraryParts,
        },
        requestVoucherGeneration: true,
      };
      onCreate(voucherParams);
      return;
    }
    params.landPackageBookingDate = includesLandPackage
      ? moment().toISOString() : undefined;
    params.installments = params.installments.map((installment) => {
      return {
        ...installment,
        amount: Number((installment.amount * 100).toFixed(2)),
        paymentMode: installment.paid ? installment.paymentMode.item.code : 'SMART_COLLECT',
        dueDate: moment(installment.dueDate).toISOString(),
      };
    });
    params.inclusions = cleanInclusionExclusion(params.inclusions);
    params.exclusions = cleanInclusionExclusion(params.exclusions);
    params.suppliers = params.suppliers.map((s) => ({
      supplier: s.supplier._id,
      costPrice: Number(s.costPrice) * 100,
      quotation: s.quotation,
      currency: s.currency._id,
      conversionRate: s.conversionRate,
    }));
    if (params.landPackageBookingDate) {
      params.routes = params.routes.map((r) => ({
        startTimeStamp: moment(r.dates.from).toISOString(),
        endTimeStamp: moment(r.dates.to).toISOString(),
        place: r.place.item._id,
      }));
    } else {
      delete params.routes;
    }
    const { pricing } = params;
    let totalSellPrice = Number(pricing.landPackage.sellPrice);
    if (pricing?.flights.sellPrice) {
      totalSellPrice += Number(pricing.flights.sellPrice);
    }
    if (pricing?.services.sellPrice) {
      totalSellPrice += Number(pricing.services.sellPrice);
    }
    let totalCostPrice = 0;
    if (pricing?.landPackage?.costPrice) {
      totalCostPrice += Number(pricing.landPackage.costPrice);
    }
    if (pricing?.flights?.costPrice) {
      totalCostPrice += Number(pricing.flights.costPrice);
    }
    if (pricing?.services?.costPrice) {
      totalCostPrice += Number(pricing.services.costPrice);
    }
    const discountPrice = Number(pricing.discount);
    params.pricing = {
      landPackage: {
        costPrice: pricing.landPackage.costPrice * 100,
        sellPrice: pricing.landPackage.sellPrice * 100,
        sellCurrency: '5879fb9a14f89e036eb59550',
        costCurrency: '5879fb9a14f89e036eb59550',
      },
      flights: {
        costPrice: pricing.flights.costPrice * 100,
        sellPrice: pricing.flights.sellPrice * 100,
        sellCurrency: '5879fb9a14f89e036eb59550',
        costCurrency: '5879fb9a14f89e036eb59550',
      },
      services: {
        costPrice: pricing.services.costPrice * 100,
        sellPrice: pricing.services.sellPrice * 100,
        sellCurrency: '5879fb9a14f89e036eb59550',
        costCurrency: '5879fb9a14f89e036eb59550',
      },
      offer: pricing.offer,
      discount: discountPrice * 100,
      totalCostPrice: totalCostPrice * 100,
      totalSellPrice: totalSellPrice * 100,
      finalSellPrice: (totalSellPrice - discountPrice) * 100,
    };
    delete params.itinerary;
    delete params.userDetails;
    onCreate(params);
  };

  validateTab = (tab) => {
    const { expert, itineraryActionType, request } = this.props;
    const {
      routes, suppliers, itineraryParts,
      userDetails, requirement,
      b2bPartnerOrderAmount,
    } = this.state;
    let valid = true;
    const errorMsg = {};
    if (this.itineraryMode === 'view') {
      return {
        valid,
        errorMsg,
      };
    }
    switch (tab) {
      case 'User details': {
        const {
          email = '',
          firstName = '',
          phone = '',
          phoneDialCode = {},
        } = userDetails;
        if (!validateEmail(email)) {
          valid = false;
          errorMsg[tab] = 'Invalid email';
        } else if (firstName.trim().length === 0) {
          valid = false;
          errorMsg[tab] = 'First name cannot be empty';
        } else if (Number(phone) === 0) {
          valid = false;
          errorMsg[tab] = 'Invalid phone number';
        } else if (!phoneDialCode.valid) {
          valid = false;
          errorMsg[tab] = 'Invalid phone dial code';
        }
        break;
      }
      case 'Trip details': {
        const { errorMsg: eMsg } = validateTripDetails(requirement);
        if (eMsg !== '') {
          valid = false;
          errorMsg[tab] = eMsg;
        }
        break;
      }
      case 'Suppliers': {
        if (suppliers.length === 0 && !expert.roles.includes(ROLES.OPERATION)) {
          valid = false;
          errorMsg[tab] = 'Enter suppliers info';
        }
        for (let i = 0; i < suppliers.length; i++) {
          const { costPrice } = suppliers[i];
          if (costPrice <= 0) {
            valid = false;
            errorMsg[tab] = 'Incomplete supplier info';
            break;
          }
        }
        break;
      }
      case 'Route': {
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i];
          if (!route.place.valid || !route.dates.from || !route.dates.to) {
            valid = false;
            errorMsg[tab] = 'Incomplete route details';
            break;
          }
        }
        break;
      }
      case 'Pricing': {
        if (
          itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
          && request.b2bPartner && !b2bPartnerOrderAmount
        ) {
          valid = false;
          errorMsg[tab] = 'Cred amount is not filled';
        }
        break;
      }
      // case 'Stays':
      //   for (let i = 0; i < routes.length; i++) {
      //     const route = routes[i];
      //     if (!route.place.valid || !route.dates.from || !route.dates.to) {
      //       valid = false;
      //       break;
      //     }
      //   }
      //   break;
      case 'Stays':
      case 'Experiences':
      case 'Transfers':
        for (let i = 0; i < itineraryParts.length; i++) {
          const part = itineraryParts[i];
          if ((part.type === 'STAY' || part.type === 'ACTIVITY' || part.type === 'TRANSFER')
            && (!part.supplier.valid || !part.supplier.item?._id)) {
            valid = false;
            errorMsg[tab] = 'Supplier not selected';
          }
        }
        break;
      default:
        break;
    }
    // extra check for Stays while confirm booking
    if (tab === 'Stays' && itineraryActionType === 'confirm_booking') {
      for (let i = 0; i < itineraryParts.length; i++) {
        const part = itineraryParts[i];
        if (part.type === 'STAY') {
          for (let j = 0; j < part.roomDetails.length; i++) {
            if (!part.roomDetails[j].referenceNumber
              || part.roomDetails[j].referenceNumber.trim() === '') {
              valid = false;
              errorMsg[tab] = 'Reference number is missing from some room(s)';
              break;
            }
          }
        }
      }
    }
    return {
      valid,
      errorMsg,
    };
  };

  handleRejection = (action, reason) => {
    const { approveRejectChanges } = this.props;
    if (action === 'reject') {
      approveRejectChanges(action, reason);
    } else if (action === 'dismiss') {
      this.setState({ rejectionReasonDialog: false });
    } else if (action === 'reject_request') {
      this.setState({ rejectionReasonDialog: true });
    } else {
      approveRejectChanges(action);
      this.setState({ rejectionReasonDialog: false });
    }
  };

  renderTabs = () => {
    const {
      classes, suggestions, conversion, expert, itinerary, isUpdatingStatus,
      getConversion, getSuggestions, meta, itineraryActionType, isFetchingSuggestions,
      request, showSnackbar,
    } = this.props;
    const {
      activeTab, userDetails, documents, itineraryData, notes, travellerDetails,
      routes, exclusions, itineraryParts, suppliers, tasksRequested, groundSupportNumber,
      inclusions, errorMsg, requirement, pricing, installments, modifyReason,
      groundSupport, b2bPartnerOrderAmount,
      otherDocuments,
    } = this.state;
    const itineraryVersion = request?.itineraries.every((itn) => itn.itineraryVersion === 2) ? 2 : 1;
    switch (activeTab) {
      case 'Customer itinerary':
        return (
          <SendItinerary
            mode="itinerary_details"
            isUpdatingStatus={false}
            onSubmit={this.nextHandler}
            onUpdate={this.handleChange}
            itineraryData={itineraryData}
            extraClass={classes.sendItineraryContainer}
          />
        );
      case 'Travellers':
        return (
          <Travellers
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            requirement={requirement}
            travellerDetails={travellerDetails}
            otherDocuments={otherDocuments}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleChange}
            nextHandler={this.nextHandler}
          />
        );
      case 'User details':
        return (
          <ItineraryUser
            mode={this.itineraryMode}
            userDetails={userDetails}
            documents={documents}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleChange}
            nextHandler={this.nextHandler}
          />
        );
      case 'Trip details': {
        return (
          <AddTripRequirement
            itineraryActionType={itineraryActionType}
            errorMsg={errorMsg[activeTab]}
            mode={this.itineraryMode}
            meta={meta}
            showRemarks={false}
            showFooter
            extraClass={classes.tripRequirement}
            suggestions={suggestions}
            tripDetails={requirement}
            getSuggestions={getSuggestions}
            onUpdate={this.handleRequirementChange}
            // handleChange={this.handleRequirementChange}
            // handleDateSelection={this.handleDateSelection}
            // handleItemSelection={this.handleTripItemSelection}
            // handleSearchChange={this.handleSearchChange}
            // removeHandler={this.removeHandler}
            nextHandler={this.nextHandler}
          />
        );
      }
      case 'Suppliers': {
        const places = requirement.places.items.map((d) => d._id);
        return (
          <Supplier
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            originalSuppliers={this.originalItinerary.suppliers}
            errorMsg={errorMsg[activeTab]}
            conversion={conversion}
            getConversion={getConversion}
            suppliers={suppliers}
            suggestions={suggestions}
            places={places}
            onUpdate={this.handleChange}
            getSuggestions={getSuggestions}
            nextHandler={this.nextHandler}
          />
        );
      }
      case 'Route':
        return (
          <RouteSelection
            itineraryActionType={itineraryActionType}
            originalRoutes={this.originalItinerary.routes}
            mode={this.itineraryMode}
            tripDates={requirement.tripDates}
            requirement={requirement}
            routes={routes}
            nextHandler={this.nextHandler}
            errorMsg={errorMsg[activeTab]}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            onUpdate={(modRoutes) => this.handleChange('routes', modRoutes)}
          />
        );
      case 'Stays':
        return (
          <StayPreview
            requirement={requirement}
            version={itineraryVersion}
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            mode={this.itineraryMode}
            suppliers={suppliers}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            routes={routes}
            transferModes={meta.transfers}
            meta={meta}
            errorMsg={errorMsg[activeTab]}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            isFetchingSuggestions={isFetchingSuggestions}
            prevHandler={this.prevHandler}
            nextHandler={this.nextHandler}
            onUpdate={this.handleItemChange}
          />
        );
      case 'Experiences':
        return (
          <ActivityPreview
            experienceCategories={meta.experienceCategories}
            tripDates={requirement.tripDates}
            transferModes={meta.transfers}
            isFetchingSuggestions={isFetchingSuggestions}
            requirement={requirement}
            itineraryActionType={itineraryActionType}
            mode="view"
            suppliers={suppliers}
            version={itineraryVersion}
            routes={routes}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
            onUpdate={this.handleItemChange}
          />
        );
      case 'Transfers':
        return (
          <TransfersPreview
            itineraryActionType={itineraryActionType}
            mode={this.itineraryMode}
            suppliers={suppliers}
            tripDates={requirement.tripDates}
            transferModes={meta.transfers}
            routes={routes}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleItemChange}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
            version={itineraryVersion}
          />
        );
      case 'Flights':
        return (
          <FlightsPreview
            itineraryActionType={itineraryActionType}
            mode={this.itineraryMode}
            routes={routes}
            suppliers={suppliers}
            tripDates={requirement.tripDates}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleItemChange}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
            showSnackbar={showSnackbar}
          />
        );
      case 'Services':
        return (
          <ServicesPreview
            itineraryActionType={itineraryActionType}
            mode={this.itineraryMode}
            getSuggestions={getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            onUpdate={this.handleItemChange}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
          />
        );
      case 'Exclusions & Inclusions':
        return (
          <InclusionExclusion
            itineraryActionType={itineraryActionType}
            mode={this.itineraryMode}
            inclusions={inclusions}
            exclusions={exclusions}
            errorMsg={errorMsg[activeTab]}
            nextHandler={this.nextHandler}
            onUpdate={this.handleChange}
            prevHandler={this.prevHandler}
          />
        );
      case 'Extras': {
        return (
          <ExtraServices
            nextHandler={this.nextHandler}
            onUpdate={this.handleChange}
            tasksRequested={tasksRequested}
          />
        );
      }
      case 'Pricing': {
        return (
          <Pricing
            itineraryActionType={itineraryActionType}
            mode={this.itineraryMode}
            itineraryParts={itineraryParts}
            suppliers={suppliers}
            pricing={pricing}
            originalPricing={this.originalItinerary.pricing}
            errorMsg={errorMsg[activeTab]}
            conversion={conversion}
            getConversion={getConversion}
            onUpdate={this.handleChange}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
            isB2bBooking={!!request.b2bPartner}
            b2bPartnerOrderAmount={b2bPartnerOrderAmount}
            expert={expert}
          />
        );
      }
      case 'Installments': {
        const { refundAmount } = this.state;
        let mode = itineraryActionType;
        if (ITINERARY_READ_MODES[itineraryActionType]) {
          mode = 'view';
        }
        return (
          <Installments
            refundAmount={refundAmount}
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            mode={mode}
            tripDates={requirement.tripDates}
            pricing={pricing}
            request={request}
            currentItinerary={itinerary}
            expert={expert}
            otherError={errorMsg[activeTab]}
            itineraryParts={itineraryParts}
            paymentModes={meta.paymentMode}
            installments={installments}
            originalInstallments={this.originalInstallments}
            onUpdate={this.handleChange}
            nextHandler={this.nextHandler}
            prevHandler={this.prevHandler}
          />
        );
      }
      case 'Notes': {
        return (
          <ItineraryNotes
            onSubmit={this.nextHandler}
            onUpdate={this.handleChange}
            itineraryActionType={itineraryActionType}
            notes={notes}
            suppliers={suppliers}
            groundSupportNumber={groundSupportNumber}
            groundSupport={groundSupport}
            isProcessing={isUpdatingStatus}
            errorMsg={errorMsg[activeTab]}
          />
        );
      }
      case 'Confirmation': {
        return (
          <ModifyConfirmation
            approveRejectChanges={this.handleRejection}
            nextHandler={this.nextHandler}
            onUpdate={this.handleChange}
            modifyReason={modifyReason}
            itineraryActionType={itineraryActionType}
            isProcessing={isUpdatingStatus}
            expert={expert}
            errorMsg={errorMsg[activeTab]}
          />
        );
      }
      default:
        return null;
    }
  };

  getPartChange = (part) => {
    const { itineraryParts } = this.state;
    let changed = false;
    const partToCheck = this.partTypeMap[part];
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].type === partToCheck
        && itineraryParts[i].proposedChange.changeType !== CHANGE_TYPES.NO_CHANGE) {
        changed = true;
        break;
      }
    }
    return changed;
  };

  getTabChange = (tab) => {
    const {
      routes, inclusions, pricing,
      exclusions, suppliers, installments,
    } = this.state;
    if (tab === 'Route') {
      return !isEqual(routes, this.originalItinerary.routes);
    }
    if (tab === 'Suppliers') {
      return !isEqual(suppliers, this.originalItinerary.suppliers);
    }
    if (tab === 'Pricing') {
      return !isEqual(pricing, this.originalItinerary.pricing);
    }
    if (tab === 'Installments') {
      return !isEqual(installments, this.originalItinerary.installments);
    }
    if (tab === 'Exclusions & Inclusions') {
      return !(isEqual(inclusions, this.originalItinerary.inclusions)
        && isEqual(exclusions, this.originalItinerary.exclusions));
    }
    return this.getPartChange(tab);
  };

  renderSections = () => {
    const { classes } = this.props;
    const { activeSection } = this.state;
    if (this.sections.length === 1) {
      return null;
    }
    return (
      <div className={classes.itinerarySections}>
        {this.sections.map((section) => {
          const selected = activeSection === section;
          return (
            <Button
              key={section}
              variant="plain"
              className={clsx(classes.sectionButton,
                selected && classes.activeSection)}
              onClick={() => this.handleSectionChange(section)}
            >
              {section}
            </Button>
          );
        })}
      </div>
    );
  };

  renderSectionTabs = () => {
    const { classes, itineraryActionType } = this.props;
    const { activeSection, activeTab } = this.state;
    const content = this.renderTabs();
    return (
      <div className={classes.bodyContainer}>
        <div className={classes.sideBar}>
          {this.tabs[activeSection].map((tab) => {
            let changed = false;
            const tabText = tab === 'Suppliers' ? 'Land Suppliers' : tab;
            if (this.tabsToCheckChange[tab] && itineraryActionType === 'approve_reject_itinerary') {
              changed = this.getTabChange(tab);
            }
            return (
              <ButtonBase
                className={clsx(classes.tabButton,
                  activeTab === tab && classes.activeTab)}
                key={tab}
                onClick={() => this.handleTabChange(tab)}
              >
                <Typography
                  className={clsx(classes.tabNames,
                    activeTab === tab && classes.activeTab,
                    changed && classes.changedTab)}
                  align="left"
                >
                  {`${tabText} ${changed ? '*' : ''}`}
                </Typography>
              </ButtonBase>
            );
          })}
        </div>
        <div className={classes.body}>
          {content}
        </div>
      </div>
    );
  };

  dismissDeleteConfirmation = () => {
    this.setState({
      deleteConfirmation: false,
      deleteIndex: -1,
    });
  };

  deleteConfirmation = () => {
    const { itineraryActionType } = this.props;
    const { itineraryParts, deleteIndex } = this.state;
    const modParts = [...itineraryParts];
    if (itineraryActionType === 'modify_itinerary') {
      const currTag = modParts[deleteIndex].proposedChange.tag;
      const foundIndex = this.getPartIndexFromTag(currTag);
      if (foundIndex !== -1) {
        this.originalItinerary
          .itineraryParts[foundIndex].proposedChange.changeType = CHANGE_TYPES.DELETE;
        modParts[deleteIndex].proposedChange.changeType = CHANGE_TYPES.DELETE;
      } else {
        modParts.splice(deleteIndex, 1);
      }
    } else {
      modParts.splice(deleteIndex, 1);
    }
    this.setState({
      itineraryParts: modParts,
      deleteConfirmation: false,
      deleteIndex: -1,
    });
  };

  render() {
    const { classes, onDismiss, onClose } = this.props;
    const { deleteConfirmation, rejectionReasonDialog } = this.state;
    const Icon = this.itineraryMode === 'view' ? Visibility : EditIcon;
    return (
      <div className={classes.container}>
        <Dialog
          open={deleteConfirmation}
          onClose={this.dismissDeleteConfirmation}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="requirement"
        >
          <Confirmation
            heading="Confirm deletion ?"
            description="Are you sure you want to delete ?"
            onReject={this.dismissDeleteConfirmation}
            onDismiss={this.dismissDeleteConfirmation}
            onConfirm={this.deleteConfirmation}
          />
        </Dialog>
        <Dialog
          open={rejectionReasonDialog}
          onClose={() => this.handleRejection('dismiss')}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="reject modification"
        >
          <RejectReason
            onReject={(reason) => this.handleRejection('reject', reason)}
            onDismiss={() => this.handleRejection('dismiss')}
          />
        </Dialog>
        <Header
          title={this.heading}
          Icon={Icon}
          iconClass={classes.iconClass}
          // img={itineraryIcon}
          onDismiss={this.itineraryMode === 'view' ? onClose : onDismiss}
          className={classes.header}
        />
        {this.renderSections()}
        {this.renderSectionTabs()}
      </div>
    );
  }
}

const styles = (theme) => createStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 940,
    height: 630,
    maxHeight: 670,
    borderRadius: 10,
    backgroundColor: theme.colors.white,
  },
  header: {
    height: 64,
    maxHeight: 64,
    boxSizing: 'border-box',
  },
  iconClass: {
    width: 28,
    height: 28,
    color: theme.colors.primary,
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    maxHeight: 562,
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // maxHeight: 600,
    // overflowY: 'auto',
  },
  tripRequirement: {
    width: 'auto',
  },
  itinerarySections: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 40,
    backgroundColor: theme.colors.primarySelected,
  },
  sendItineraryContainer: {
    width: '100%',
    height: '100%',
  },
  sectionButton: {
    color: theme.colors.textLight,
    fontSize: 14,
    fontWeight: 'normal',
    flex: 1,
  },
  activeSection: {
    color: theme.colors.primary,
    fontWeight: 'bold',
    width: 140,
  },
  sideBar: {
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '22%',
    maxWidth: 240,
    borderRight: `1px solid ${theme.colors.underline}`,
  },
  tabButton: {
    height: 36,
    padding: '5px 10px',
    fontSize: 14,
    letterSpacing: 0.5,
    color: theme.colors.textDark_1,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  activeTab: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    backgroundColor: theme.colors.primaryLight,
  },
  changedTab: {
    color: `${theme.colors.brown} !important`,
  },
  tabNames: {
    width: '100%',
    paddingLeft: 20,
  },
});

ItineraryDetails.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  markTouched: PropTypes.func,
  suggestions: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  expert: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  activeBookingId: PropTypes.number.isRequired,
  onBookingModify: PropTypes.func.isRequired,
  itinerary: PropTypes.object,
  itineraryActionType: PropTypes.string,
  getConversion: PropTypes.func.isRequired,
  conversion: PropTypes.object.isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  approveRejectChanges: PropTypes.func.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(ItineraryDetails);
