import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Footer from 'UI/footer';
import Button from 'UI/button';
import MultiSelect from 'UI/multiSelect';
import { filterList, getDateStr, processRequirement } from 'utils/common';
import DateRangeSelection from 'UI/dateRangeSelection';
import TravellerSelection from 'UI/travellerSelection';
import Checkbox from 'UI/checkbox';
import LabelInput from 'UI/labelInput';
import axios from 'axios';
import { TIMEOUT } from 'utils/consts';
import * as _ from 'lodash';
import { baseURL } from 'utils/apiEndpoints';

class ItineraryRequirement extends Component {
  constructor(props) {
    super(props);
    const {
      request: { tags },
      requirement,
      meta,
    } = this.props;
    this.state = {
      origin: {
        value: '',
        valid: false,
        items: [],
      },
      places: {
        value: '',
        valid: false,
        items: [],
      },
      tripDate: '',
      tripDates: {
        from: '',
        to: '',
      },
      duration: 0,
      flightsBooked: false,
      isFlexible: false,
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      tripTypes: {
        value: '',
        valid: false,
        items: [],
      },
      errorMsg: '',
      tags: (tags || []).filter((x) => x === 'DATES_NOT_FINAL'),
      ...processRequirement(requirement, meta.tripTypes),
    };

    this.debouncedHandleSearchChange = _.debounce(this.handleSearchChange, 500);
  }

  componentDidMount() {
    const { fetchedPlaces, request, selectedItinerary } = this.props;
    const { origin, places, tripDates } = this.state;
    const arrivalDate = moment(request.requirement.departureDate).add(selectedItinerary.requirement.duration, 'days').toISOString();
    this.setState({
      origin: {
        ...origin,
        items: origin.items.map((i) => fetchedPlaces.find((r) => r._id === i)),
        options: fetchedPlaces,
      },
      places: {
        ...places,
        items: selectedItinerary?.requirement.places.map((i) => fetchedPlaces.find((r) => r._id === i)),
        options: fetchedPlaces,
      },
      tripDates: {
        ...tripDates,
        to: moment(arrivalDate).toDate(),
      },
      duration: request.requirement.duration,
      tripDate: getDateStr(request.requirement.departureDate, arrivalDate),
    });
  }

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    const changes = {};
    if (itm.constructor.name === 'Object' && field !== 'travellers') {
      changes[field] = {
        ...itm,
        value,
        valid: false,
      };
    } else {
      changes[field] = value;
    }
    if (field === 'flightsBooked' && value) {
      changes.isFlexible = false;
    } else if (field === 'isFlexible' && value) {
      changes.flightsBooked = false;
    }
    this.setState({ ...changes });
  };

  handleDateSelection = (date, dateStr) => {
    const { from, to } = date;
    let changes = {};
    if (from && to) {
      changes = {
        duration: `${moment(to)
          .diff(moment(from)) / 86400000}`,
      };
    }
    this.setState({
      tripDate: dateStr,
      ...changes,
      tripDates: date,
    });
  };

  handleItemSelection = (payload) => {
    const { field, item } = payload;

    const { [field]: fieldInState } = this.state;
    const { items = [] } = fieldInState;
    this.setState({
      [field]: {
        value: '',
        valid: true,
        items: _.unionBy(items, [item], (i) => i._id),
      },
    });
  };

  handleItemRemoval = (payload) => {
    const { field, item } = payload;

    const { [field]: fieldInState } = this.state;
    const { items = [] } = fieldInState;
    this.setState({
      [field]: {
        value: '',
        valid: true,
        items: _.differenceBy(items, [item], (i) => i._id),
      },
    });
  };

  addTentative = () => {
    const { tags } = this.state;
    const index = tags.indexOf('DATES_NOT_FINAL');
    if (index === -1) {
      this.setState({
        tags: [...tags, 'DATES_NOT_FINAL'],
      });
    } else {
      const modData = [...tags];
      modData.splice(index, 1);
      this.setState({ tags: modData });
    }
  };

  handleSearchChange = (payload) => {
    const { showSnackbar } = this.props;
    const { field, searchType, value } = payload;
    const queryData = {
      query: value,
      limit: 10,
    };
    if (searchType === 'cities') {
      queryData.includeAddressTypes = ['locality'];
    } else if (searchType === 'places') {
      queryData.includeAddressTypes = ['country'];
      queryData.includeIndianPlaces = true;
    }
    axios({
      method: 'post',
      url: `${baseURL}place/suggest`,
      headers: this.headers,
      timeout: TIMEOUT,
      data: queryData,
    }).then((response) => {
      const { [field]: fieldInState = {} } = this.state;
      this.setState({
        [field]: {
          ...fieldInState,
          options: response?.data?.places || [],
        },
      });
    }).catch(() => {
      showSnackbar('Something went wrong, please retry', 'error');
    });
  };

  removeHandler = (field, accessor, item) => {
    const { [field]: varField } = this.state;
    const modField = { ...varField };
    modField.items = modField.items.filter((d) => d[accessor] !== item[accessor]);
    this.setState({ [field]: modField });
  };

  validityChecker = (setError) => {
    const {
      origin, places, tripDates,
      travellers, tripTypes,
    } = this.state;
    let error = false;
    let errorMsg = '';
    if (origin.items.length === 0) {
      error = true;
      errorMsg = 'Origin cannot be empty';
    } else if (places.items.length === 0) {
      error = true;
      errorMsg = 'Destination cannot be empty';
    } else if (!tripDates.from || !tripDates.to) {
      error = true;
      errorMsg = 'Trip dates not selected';
    } else if (tripTypes.items.length === 0) {
      error = true;
      errorMsg = 'Trip type not selected';
    } else if (travellers.adults === 0) {
      error = true;
      errorMsg = 'No adults selected for the trip';
    }
    if (setError) {
      this.setState({ errorMsg });
    }
    return { error, errorMsg };
  };

  submitHandler = () => {
    const { error } = this.validityChecker(true);
    if (error) {
      return;
    }
    const { onSubmit, selectedItinerary } = this.props;
    const {
      origin, places, tripDates: { from, to }, isFlexible,
      travellers, tripTypes, duration, flightsBooked,
      tags,
    } = this.state;
    const params = {
      origin: origin.items.map((o) => o._id),
      places: places.items.map((d) => d._id),
      departureDate: moment(from).toISOString(),
      arrivalDate: moment(to).toISOString(),
      duration,
      travellers,
      tripTypes: tripTypes.items.map((t) => t.code),
      flightsBooked,
      flexible: isFlexible,
      tags,
      templateItineraryId: selectedItinerary._id,
    };
    onSubmit(params);
  };

  render() {
    const {
      classes,
      isLoading,
      meta,
    } = this.props;
    const {
      origin, places, tripDates, travellers,
      flightsBooked, errorMsg, tripDate, tripTypes,
    } = this.state;
    const tripTypesList = filterList(tripTypes.value, meta.tripTypes, ['title']) || [];
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.row}>
            <MultiSelect
              extraClass={classes.wrapperLeft}
              inputClass={classes.input}
              label="Origin city"
              selections={origin.items}
              value={origin.value}
              accessor="name"
              renderType="place"
              data={origin.options}
              removeHandler={(item) => this.handleItemRemoval({ field: 'origin', item })}
              onChange={(value) => {
                this.setState({
                  origin: {
                    ...origin,
                    value,
                  },
                });
                this.debouncedHandleSearchChange({ field: 'origin', searchType: 'cities', value });
              }}
              onSelected={(item) => this.handleItemSelection({ field: 'origin', item })}
            />
            <MultiSelect
              extraClass={classes.wrapperRight}
              inputClass={classes.input}
              label="Destination"
              selections={places.items}
              value={places.value}
              accessor="name"
              renderType="place"
              data={places.options}
              removeHandler={(item) => this.handleItemRemoval({ field: 'places', item })}
              onChange={(value) => {
                this.setState({
                  places: {
                    ...places,
                    value,
                  },
                });
                this.debouncedHandleSearchChange({ field: 'places', searchType: 'places', value });
              }}
              onSelected={(item) => this.handleItemSelection({ field: 'places', item })}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.itemRow}>
              <Checkbox
                disabled={false}
                checked={flightsBooked}
                label="Flights booked"
                onChange={() => this.handleChange('flightsBooked', !flightsBooked)}
              />
              {
                /*
                <Checkbox
                  disabled={flightsBooked}
                  checked={(tags || []).includes('DATES_NOT_FINAL')}
                  label="Dates not final"
                  onChange={this.addTentative}
                />
                */
              }
            </div>
            <LabelInput
              label="Trip Dates"
              extraClass={classes.wrapperRight}
              containerStyle={{ marginLeft: 0 }}
            >
              <DateRangeSelection
                value={`${tripDate}${tripDates.from && tripDates.to ? ` (${moment(tripDates.to).diff(tripDates.from, 'days')}N)` : ''}`}
                dates={tripDates}
                onSelected={(date, dateStr) => this.handleDateSelection(date, dateStr)}
              />
            </LabelInput>
          </div>
          <div className={classes.row}>
            <TravellerSelection
              label="NO. OF TRAVELLERS"
              extraClass={classes.wrapperLeft}
              travellers={travellers}
              onChange={(val) => this.handleChange('travellers', val)}
            />
            <MultiSelect
              label="TRIP TYPE"
              selections={tripTypes.items}
              value={tripTypes.value}
              extraClass={classes.wrapperRight}
              inputClass={classes.input}
              data={tripTypesList}
              accessor="title"
              removeHandler={(item) => this.removeHandler('tripTypes', 'title', item)}
              onChange={(val) => this.handleChange('tripTypes', val)}
              onSelected={(item) => this.handleItemSelection('tripTypes', 'title', item)}
            />
          </div>
          <Footer errorMsg={errorMsg}>
            <Button
              loading={isLoading}
              onClick={() => this.submitHandler()}
              className={classes.submitButton}
            >
              Edit Template
            </Button>
          </Footer>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.colors.white,
  },
  spaceBetween: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '20%',
  },
  iconClass: {
    color: theme.colors.primary,
  },
  body: {
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: `1px solid ${theme.colors.underline}`,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    alignItems: 'center',
  },
  itemRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingRight: 20,
  },
  input: {
    padding: '0px 5px 0px 15px',
    height: '100%',
    width: '100%',
  },
  wrapperRight: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    maxWidth: '50%',
    marginLeft: 10,
  },
  wrapperLeft: {
    flex: 1,
    borderRadius: 4,
    maxWidth: '50%',
    width: '100%',
    marginRight: 10,
  },
  stepNumberBar: {
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderRight: `1px solid ${theme.colors.underline}`,
  },
  tabButton: {
    width: '50%',
    borderRadius: '20px',
    height: 36,
    padding: '5px 10px',
    fontSize: 14,
    letterSpacing: 0.5,
    color: theme.colors.textDark_1,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  activeTab: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    backgroundColor: theme.colors.primaryLight,
  },
  itineraryTemplateInput: {
    padding: '5px 15px',
    marginTop: '20px',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
});

ItineraryRequirement.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requirement: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  selectedItinerary: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  request: PropTypes.object,
  showSnackbar: PropTypes.func.isRequired,
  fetchedPlaces: PropTypes.object.isRequired,
};

export default withStyles(styles)(ItineraryRequirement);
