import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/styles/withStyles';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Button from 'UI/button';
// import Autocomplete from 'UI/autocomplete';
import DateButton from 'UI/dateButton';
import LabelInput from 'UI/labelInput';
import Footer from 'UI/footer';
import {
  getValidDates,
  validateInstallmentConditions,
  uuid,
  shouldShowDiffToggle,
  deepClone,
  getLastInstallmentCollectionDate,
} from 'utils/common';
import { ITINERARY_READ_MODES, ROLES } from 'utils/consts';
import Switch from 'UI/switch';
import * as _ from 'lodash';
import InstallmentDiff from 'components/itineraryMaker/installmentDiff';

class Installments extends Component {
  constructor(props) {
    super(props);
    const {
      installments, onUpdate, expert, request, tripDates,
      originalInstallments, itineraryActionType,
    } = this.props;
    if (installments.length === 0) {
      const modInstallments = [this.getInstallmentTemplate(true)];
      onUpdate('installments', modInstallments);
    }
    this.disabledDatesFn = this.enabledDates(tripDates);
    this.buttonText = 'Save & Close';
    if (itineraryActionType === 'order_modify') {
      this.buttonText = 'Modify';
    } else if (itineraryActionType === 'accept_payment') {
      this.buttonText = 'Accept payment';
    }
    this.state = {
      errorMsg: '',
      totalSP: 0,
      valid: true,
      showDiff: false,
    };
    this.originalInstallments = [];
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType,
      { installments: originalInstallments });
    if (this.showDiffToggle) {
      this.originalInstallments = originalInstallments;
    }
    this.getInstallmentDeadLines();
    this.isAdmin = expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.ADMIN);
    this.operation = expert.roles.includes(ROLES.OPERATION) || this.isAdmin;
    this.showRefundSection = false;
    const { refundAmount, shouldRefund } = this.shouldRefundAmount();
    if (shouldRefund) {
      this.showRefundSection = true;
      onUpdate('refundAmount', refundAmount);
    }
    const newInstallments = this.getReducedInstallments(refundAmount);
    onUpdate('installments', newInstallments);
    this.originalCopy = [];
    if (request.orderInfo?.orderData) {
      this.originalCopy = deepClone(request.orderInfo.orderData[0].installments);
    }
    // this.allowedModes = paymentModes;
    // if (!this.isAdmin) {
    //   this.allowedModes = paymentModes.filter((pm) => pm.code !== 'PAYMENT_LINK');
    // }
  }

  getReducedInstallments = (refundAmount) => {
    const {
      installments, itineraryActionType,
    } = this.props;
    let leftVal = this.getLeftVal();
    if ((itineraryActionType === 'modify_itinerary' || itineraryActionType === 'modify_itinerary_tech')
      && leftVal < 0) {
      // we are reducing the amount from installments automatically
      const newInstallments = [...installments];
      leftVal *= -1;
      leftVal -= refundAmount;
      for (let i = installments.length - 1; i >= 0; i--) {
        const amount = installments[i].amount;
        if (amount <= leftVal) {
          newInstallments.splice(i, 1);
          leftVal -= amount;
        } else {
          newInstallments[i].amount -= leftVal;
          break;
        }
      }
      return newInstallments;
    }
    return installments;
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  getInstallmentTemplate = (token) => {
    const { installments } = this.props;
    let dueDate;
    if (installments.length) {
      dueDate = moment(installments[installments.length - 1].dueDate)
        .add(1, 'day')
        .toDate();
    } else {
      dueDate = moment().endOf('day').toDate();
    }
    const installment = {
      uniqueId: uuid(),
      amount: 0,
      dueDate,
      referenceNumber: '',
      paymentMode: {
        value: 'Smart Collect',
        valid: true,
        item: {
          code: 'SMART_COLLECT',
          title: 'Smart Collect',
        },
      },
      paid: false,
    };
    if (token) {
      installment.amount = 5000;
    } else {
      installment.amount = this.getLeftVal();
    }
    // if (!token && !this.isAdmin) {
    //   installment.paymentMode = {
    //     value: 'Smart Collect',
    //     valid: true,
    //     item: {
    //       code: 'SMART_COLLECT',
    //       title: 'Smart Collect',
    //     },
    //   };
    // }
    return installment;
  };

  addInstallment = () => {
    const { installments, onUpdate } = this.props;
    const modInstallments = [...installments];
    modInstallments.push(this.getInstallmentTemplate());
    onUpdate('installments', modInstallments);
  };

  addCollectionCondition = (collections, date, amount, errorMsg) => {
    const modCollections = { ...collections };
    if (!modCollections[date]) {
      modCollections[date] = {
        till: moment(date, 'DD MMM YY')
          .endOf('day')
          .unix() * 1000,
        amount: Number(amount),
        errorMsgs: [errorMsg],
      };
    } else {
      modCollections[date].amount += Number(amount);
      modCollections[date].errorMsgs.push(errorMsg);
    }
    return modCollections;
  };

  getItineraryConditions = (collectionConditions, itinerary) => {
    const { itineraryParts, pricing, landPackageBookingDate } = itinerary;
    let flightPrice = 0;
    let servicePrice = 0;
    let landPrice = 0;
    let modCollections = { ...collectionConditions };
    let includesLandPackage = false;
    for (let i = 0; i < itineraryParts.length; i++) {
      const sellingPrice = +(itineraryParts[i].sellPrice) / 100;
      if (itineraryParts[i].type === 'FLIGHT') {
        const bookingDate = moment(itineraryParts[i].bookingDate)
          .format('DD MMM YY');
        if (itineraryParts[i].refundable) {
          const cautionAmount = itineraryParts[i].cancellationAmount + 2000;
          const flexAmount = sellingPrice - cautionAmount;
          const flexDate = moment(itineraryParts[i].bookingDate)
            .add(10, 'days')
            .format('DD MMM YY');
          modCollections = this.addCollectionCondition(modCollections, bookingDate, cautionAmount, 'Flight caution amount');
          modCollections = this.addCollectionCondition(modCollections, flexDate, flexAmount, 'Rest flight amount');
        } else {
          modCollections = this.addCollectionCondition(modCollections, bookingDate, sellingPrice, 'Flight amount');
        }
        flightPrice += +sellingPrice;
      } else if (itineraryParts[i].type === 'SERVICE') {
        servicePrice += +sellingPrice;
        const bookingDate = moment(itineraryParts[i].bookingDate || itineraryParts[i].created_at)
          .format('DD MMM YY');
        modCollections = this.addCollectionCondition(modCollections, bookingDate, sellingPrice, 'Service amount');
      } else {
        includesLandPackage = true;
      }
    }
    if (includesLandPackage) {
      const sellingPrice = Number(pricing.landPackage.sellPrice);
      landPrice = Number(sellingPrice) - Number(pricing.discount);
      landPrice /= 100;
      const bookingDate = moment(landPackageBookingDate)
        .add(24, 'hours')
        .format('DD MMM YY');
      if (landPrice >= 5000) {
        modCollections = this.addCollectionCondition(modCollections, bookingDate, 5000, 'Token amount');
      }
      let next3Days = moment(landPackageBookingDate).add(3, 'days').format('DD MMM YY');
      if (moment(next3Days, 'DD MMM YY').unix() >= moment(this.lastCollectionDate).unix()) {
        next3Days = moment(this.lastCollectionDate).format('DD MMM YY');
      }
      modCollections = this.addCollectionCondition(modCollections,
        next3Days, (landPrice * 0.1) - 5000, '10% of package amount');
      const finalCollectionDate = this.lastCollectionDate.format('DD MMM YY');
      modCollections = this.addCollectionCondition(modCollections,
        finalCollectionDate, (landPrice * 0.9), 'Rest of land package amount');
    }
    return {
      conditions: modCollections,
      flightPrice,
      landPrice,
      servicePrice,
    };
  };

  getInstallmentDeadLines = () => {
    const {
      itineraryParts,
      pricing,
      request,
      itineraryActionType,
      currentItinerary,
    } = this.props;
    this.flights = [];
    this.services = [];
    this.totalFlightPrice = 0;
    this.totalServicePrice = 0;
    this.landPackagePrice = 0;
    let collectionConditions = {};
    for (let i = 0; i < request.itineraries.length; i++) {
      const itinerary = request.itineraries[i];
      if (['APPROVED', 'AWAITING_MODIFICATION_APPROVAL', 'CONFIRMED'].includes(itinerary.status)) {
        if (!(currentItinerary && currentItinerary._id === itinerary._id
          && (itineraryActionType === 'modify_itinerary' || itineraryActionType === 'modify_itinerary_tech'
            || itineraryActionType === 'approve_reject_itinerary'))) {
          const {
            conditions, landPrice, flightPrice, servicePrice,
          } = this.getItineraryConditions(collectionConditions, itinerary, this.lastCollectionDate);
          collectionConditions = conditions;
          this.totalFlightPrice += flightPrice;
          this.totalServicePrice += servicePrice;
          this.landPackagePrice += landPrice;
        }
      }
    }
    if (itineraryActionType === 'request_booking' || itineraryActionType === 'create_ops_itinerary'
      || itineraryActionType === 'modify_itinerary_tech' || itineraryActionType === 'modify_itinerary'
      || itineraryActionType === 'approve_reject_itinerary') {
      const {
        conditions, landPrice, flightPrice, servicePrice,
      } = this.getItineraryConditions(collectionConditions, {
        pricing: {
          landPackage: {
            sellPrice: pricing.landPackage.sellPrice * 100,
          },
          discount: Number(pricing.discount) * 100,
        },
        itineraryParts,
        landPackageBookingDate: moment().toISOString(),
      }, this.lastCollectionDate);
      collectionConditions = conditions;
      this.totalFlightPrice += flightPrice;
      this.totalServicePrice += servicePrice;
      this.landPackagePrice += landPrice;
    }
    this.totalSP = this.landPackagePrice + this.totalFlightPrice + this.totalServicePrice;
    this.state.totalSP = this.totalSP;
    this.collectionConditions = collectionConditions;
  };

  handleChange = (index, field, value) => {
    const { installments, onUpdate } = this.props;
    const modInstallments = [...installments];
    const { [field]: itm } = modInstallments[index];
    if (itm.constructor.name === 'Object') {
      modInstallments[index] = {
        ...modInstallments[index],
        [field]: {
          value,
          valid: false,
        },
      };
    } else {
      let finalValue = field === 'amount' ? Number(value) : value;
      if (field === 'dueDate') {
        finalValue = moment(value)
          .endOf('day')
          .toDate();
      }
      modInstallments[index] = {
        ...modInstallments[index],
        [field]: finalValue,
      };
    }
    onUpdate('installments', modInstallments);
  };

  handleSelection = (index, field, item) => {
    const { installments, onUpdate } = this.props;
    const modInstallments = [...installments];
    modInstallments[index] = {
      ...modInstallments[index],
      [field]: {
        value: item.title,
        valid: true,
        item,
      },
    };
    onUpdate('installments', modInstallments);
  };

  removeHandler = (index) => {
    const { installments, onUpdate } = this.props;
    const modInstallments = [...installments];
    modInstallments.splice(index, 1);
    if (modInstallments.length === 0) {
      modInstallments.push(this.getInstallmentTemplate(true));
    }
    onUpdate('installments', modInstallments);
  };

  refundAmountHandler = (refundAmount) => {
    const { onUpdate } = this.props;
    onUpdate('refundAmount', Number(refundAmount));
  };

  getLeftVal = () => {
    const { request, installments, refundAmount = 0 } = this.props;
    const { totalSP } = this.state;
    let refundIgnoreAmount = 0;
    if (request.orderInfo?.orderData) {
      refundIgnoreAmount += request.orderInfo.orderData[0].refundedAmount / 100;
      refundIgnoreAmount += request.orderInfo.orderData[0].pendingRefundAmount / 100;
    }
    const amtSoFar = installments.reduce((t, c) => t + Number(c.amount || 0), 0);
    return +((totalSP - amtSoFar).toFixed(2)) + Number(refundAmount) + Number(refundIgnoreAmount);
  };

  shouldRefundAmount = () => {
    const { request, itineraryActionType } = this.props;
    const { totalSP } = this.state;
    if (!request.orderInfo?.orderData) {
      return {
        shouldRefund: false,
        refundAmount: 0,
      };
    }
    const { actualAmountPaid } = request.orderInfo.orderData[0];
    let orderRefund = false;
    if (totalSP < (actualAmountPaid / 100)) {
      orderRefund = true;
    }
    if ((itineraryActionType === 'modify_itinerary' || itineraryActionType === 'modify_itinerary_tech'
      || itineraryActionType === 'approve_reject_itinerary')
      && orderRefund) {
      const refundAmount = (actualAmountPaid / 100) - totalSP;
      return {
        shouldRefund: true,
        refundAmount,
      };
    }
    return {
      shouldRefund: false,
      refundAmount: 0,
    };
  };

  enabledDates = (tripDates) => {
    const today = moment().startOf('day').unix();
    const lastCollectionDate = getLastInstallmentCollectionDate(tripDates);
    const disabledDateFn = getValidDates(moment((today - 86400) * 1000), lastCollectionDate);
    this.lastCollectionDate = lastCollectionDate;
    return disabledDateFn;
  };

  relaxedDatesFn = () => {
    return false;
  };

  nextHandler = () => {
    const {
      installments, nextHandler, expert, request,
      shouldRelaxCheck, request: { crmLead }, tripDates,
    } = this.props;
    let msg = '';
    if (Number(this.getLeftVal()) !== 0) {
      msg = 'Installment amount should be equal to selling price';
    }
    if (crmLead && msg === '' && shouldRelaxCheck && this.operation) {
      nextHandler();
      return;
    }
    if (crmLead && msg === '') {
      nextHandler();
      return;
    }
    const enableRelaxedCheck = expert.roles.includes(ROLES.TEAM_LEAD) && shouldRelaxCheck;
    for (let i = 0; i < installments.length; i++) {
      const {
        amount,
        dueDate, amountToBePaid,
      } = installments[i];
      // if (amount > 40000 && paymentMode.value === 'Payment Link') {
      //   msg = 'Payment link amount cannot be more than equal to 40,000';
      //   break;
      // }
      // if (amount > 50000 && paymentMode.value === 'Cash') {
      //   msg = 'Cash amount cannot be more than equal to 50,000';
      //   break;
      // }
      const momentDate = moment(dueDate);
      if (momentDate.weekday() === 7) {
        msg = 'Installment cannot be scheduled on Sunday';
        break;
      }
      if (enableRelaxedCheck && momentDate.isAfter(tripDates.from)) {
        msg = 'Installment should be collected before trip starts';
        break;
      }
      if (amount < (amountToBePaid / 100)) {
        msg = `${i + 1} Installment amount cannot be less than amount already paid`;
        break;
      }
      if (amount <= 0) {
        msg = 'Installment amount cannot be <= 0';
        break;
      }
    }
    if (enableRelaxedCheck) {
      nextHandler();
      return;
    }
    const considerAmount = request.orderInfo?.orderData?.length
      && request.orderInfo.orderData[0].orderAmount;
    if (!installments[0].paid && installments[0].amount < 5000 && considerAmount > 5000) {
      msg = 'Token amount should be at least 5000 Rs.';
    }
    if (msg !== '') {
      this.setState({ errorMsg: msg });
      return;
    }
    this.getInstallmentDeadLines();
    const {
      valid, errorMsg,
    } = validateInstallmentConditions(installments.map((i) => ({
      ...i,
      dueDate: moment(i.dueDate)
        .toISOString(),
    })), this.collectionConditions);
    if (nextHandler) {
      nextHandler();
      return;
    }
    if (!valid) {
      this.setState({ errorMsg });
      return;
    }
    nextHandler();
  };

  render() {
    const {
      classes, installments, itineraryActionType, tripDates,
      isProcessing, otherError, shouldRelaxCheck, refundAmount,
    } = this.props;
    const {
      errorMsg, valid,
      showDiff,
    } = this.state;
    const leftVal = this.getLeftVal();
    this.enabledDates(tripDates);
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          {this.showDiffToggle ? (
            <div className={classes.changeHeader}>
              <Switch
                checked={showDiff}
                onToggle={this.toggleDiff}
                label="VIEW CHANGES"
              />
            </div>
          ) : null}
          <div className={classes.header}>
            <Typography className={classes.headTitleSpacing}>&nbsp;</Typography>
            <Typography className={classes.headLabel}>Amount</Typography>
            <Typography className={classes.headLabel}>Due date</Typography>
            {/* <Typography className={classes.headLabel}>Payment method</Typography> */}
            <Typography className={classes.headDeleteSpacing}>&nbsp;</Typography>
          </div>
          {showDiff ? (
            <InstallmentDiff oldData={this.originalInstallments} data={installments} />
          )
            : (
              <>
                {installments.map((installment, index) => {
                  const {
                    uniqueId, amount, dueDate, amountToBePaid,
                    paid = false, referenceNumber,
                  } = installment;
                  let originalAmount = 0;
                  if (this.originalCopy[index]) {
                    originalAmount = (this.originalCopy[index].amount || 0) / 100;
                  }
                  const showPartialAmount = amountToBePaid
                    && paid === false && amount !== amountToBePaid;
                  return (
                    <div className={classes.installmentRow} key={uniqueId}>
                      <Typography className={classes.installmentText}>
                        {`${index === 0 ? 'Token' : `Installment - ${index}`}`}
                      </Typography>
                      <LabelInput
                        value={amount !== 0 ? amount.toString().replace(/^0/, '') : amount}
                        extraClass={clsx(classes.inputClass, classes.amountClass)}
                        inputProps={{
                          type: 'number',
                          endAdornment: showPartialAmount ? (
                            <Typography
                              className={classes.dueAmount}
                            >
                              {`${_.round(originalAmount - amountToBePaid, 2)} / ${originalAmount} Paid`}
                            </Typography>
                          ) : undefined,
                        }}
                        disabledInput={paid}
                        onChange={(val) => this.handleChange(index, 'amount', val)}
                      />
                      {(itineraryActionType === 'accept_payment') ? (
                        <LabelInput
                          value={referenceNumber}
                          disabledInput={paid}
                          extraClass={classes.inputClass}
                          onChange={(val) => this.handleChange(index, 'referenceNumber', val)}
                        />
                      ) : null}
                      <LabelInput
                        extraClass={clsx(classes.inputClass, classes.dueClass)}
                      >
                        <DateButton
                          value={dueDate}
                          disabled={paid}
                          maxDate={dueDate}
                          disablePast={!shouldRelaxCheck}
                          shouldDisableDate={shouldRelaxCheck
                            ? this.relaxedDatesFn : this.disabledDatesFn}
                          onChange={(val) => this.handleChange(index, 'dueDate', val)}
                        />
                      </LabelInput>
                      {/* <Autocomplete
                      disabled={paid}
                      extraClass={classes.autocompleteWrapper}
                      inputClass={classes.autocompleteInput}
                      bodyClass={classes.bodyClass}
                      value={paymentMode.value}
                      accessor="title"
                      onChange={(val) => this.handleChange(index, 'paymentMode', val)}
                      onSelected={(item) => this.handleSelection(index, 'paymentMode', item)}
                      data={index !== 0 ? this.allowedModes : paymentModes}
                    /> */}
                      {!(paid || itineraryActionType === 'accept_payment') ? (
                        <IconButton
                          className={classes.deleteBtn}
                          onClick={() => this.removeHandler(index)}
                        >
                          <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                      ) : <div className={classes.deletePlaceholder} />}
                    </div>
                  );
                })}
              </>
            )}
          {leftVal !== 0 ? (
            <div className={classes.row}>
              <Typography className={classes.leftText}>Amount left:</Typography>
              <Typography className={classes.leftVal}>{leftVal}</Typography>
            </div>
          ) : null}
          {(this.showRefundSection || refundAmount !== 0) ? (
            <div className={classes.refundSection}>
              <LabelInput
                label="Refund amount"
                value={refundAmount}
                extraClass={classes.refundInput}
                inputProps={{
                  type: 'number',
                }}
                onChange={this.refundAmountHandler}
              />
            </div>
          ) : null}
          {
            (itineraryActionType !== 'accept_payment' && !ITINERARY_READ_MODES[itineraryActionType]
              && !showDiff)
              ? (
                <Button
                  onClick={this.addInstallment}
                  className={classes.addBtn}
                  variant="plain"
                >
                  + Add installment
                </Button>
              )
              : null
          }
        </div>
        <Footer errorMsg={errorMsg || otherError}>
          <Button
            loading={isProcessing}
            onClick={this.nextHandler}
            className={clsx(classes.createButton, !valid && classes.disabled)}
          >
            {this.buttonText}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 40px',
  },
  changeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    textAlign: 'center',
  },
  headTitleSpacing: {
    width: 140,
  },
  headDeleteSpacing: {
    width: 40,
  },
  headLabel: {
    color: theme.colors.textLight,
    fontWeight: 'bold',
    fontSize: 12,
    minWidth: 130,
    flex: 1,
  },
  installmentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  installmentText: {
    fontSize: 14,
    color: theme.colors.textDark,
    minWidth: 100,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  amountClass: {
    '& > div > input': {
      textAlign: 'right',
    },
  },
  dueAmount: {
    backgroundColor: theme.colors.underline,
    fontSize: 10,
    color: theme.colors.red,
    fontWeight: 'bold',
    marginRight: -15,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    width: 'auto',
    minWidth: 90,
    borderRadius: 4,
    textAlign: 'center',
  },
  dueClass: {
    cursor: 'pointer',
    height: 40,
    alignItems: 'center',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  inputClass: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    minWidth: 110,
    marginRight: 10,
  },
  inputContainer: {
    color: theme.colors.black,
    flex: 1,
    marginLeft: 16,
    padding: '10px 15px',
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  input: {
    textAlign: 'right',
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary}`,
  },
  profit: {
    color: theme.colors.green,
  },
  loss: {
    color: theme.colors.red,
  },
  autocompleteWrapper: {
    boxSizing: 'border-box',
    maxWidth: '180px',
    height: '40px',
  },
  bodyClass: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  autocompleteInput: {
    padding: '6px 10px',
    fontSize: 14,
    height: '100%',
    width: '100%',
  },
  addBtn: {
    width: 180,
    marginRight: 'auto',
  },
  deleteBtn: {
    padding: 4,
    marginLeft: 10,
  },
  deleteIcon: {
    color: theme.colors.textDark,
    width: 16,
    height: 16,
  },
  deletePlaceholder: {
    height: 10,
    width: 34,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  leftText: {
    color: theme.colors.textDark,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    paddingRight: 10,
  },
  leftVal: {
    fontWeight: 'bold',
    color: theme.colors.red,
    fontSize: 16,
  },
  createButton: {
    minWidth: 150,
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  rejectButton: {
    color: theme.colors.red,
    backgroundColor: theme.colors.white,
    marginRight: 10,
    border: `1px solid ${theme.colors.red}`,
    width: 120,
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    border: `1px solid ${theme.colors.grey} !important`,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  refundSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
    padding: '10px 0',
    borderTop: `1px solid ${theme.colors.border}`,
  },
  refundInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    minWidth: 110,
    marginRight: 10,
    '& > div > input': {
      textAlign: 'right',
    },
  },
});

Installments.propTypes = {
  classes: PropTypes.object,
  installments: PropTypes.array.isRequired,
  pricing: PropTypes.object,
  request: PropTypes.object.isRequired,
  itineraryParts: PropTypes.array.isRequired,
  paymentModes: PropTypes.array.isRequired,
  tripDates: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func.isRequired,
  expert: PropTypes.object.isRequired,
  // mode: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  otherError: PropTypes.string,
  shouldRelaxCheck: PropTypes.bool,
  currentItinerary: PropTypes.object,
  itineraryActionType: PropTypes.string,
  originalInstallments: PropTypes.array,
  refundAmount: PropTypes.number,
};

export default withStyles(styles)(Installments);
